.previous-accordian {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: rem(25px);
  border-bottom: 1px solid $grey-medium;
  padding-bottom: rem(25px);
}

.previous-accordian__title {
  opacity: .25;
  font-size: em(22px);
  line-height: rem(25px);
  align-self: flex-start;
}
