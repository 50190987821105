.radio {
  @extend .checkbox;

  input + .checkbox__label {
    &:before {
      border-radius: 20px;
    }

    svg {
      display: block;
      height: rem(11px);
      width: rem(11px);
      left: rem(5px);
      top: rem(9px);
      color: white;
    }
  }

  input:checked + .checkbox__label:before {
    background-color: $green;
    border-color: $green;
  }
}
