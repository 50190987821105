$border-bottom-color: #93a9ff;

.vendor-search {
  color: $white;
  font-size: rem(22px);
  padding: 15px 0;

  @include breakpoint($tablet) {
    font-size: rem(32px);
  }
}

.vendor-search__datepicker {
  display: inline-block;

  .u-unstyled-btn {
    border-bottom: 2px dotted $border-bottom-color;
    height: rem(40px);
    padding-right: 20px;
    overflow: hidden;
  }

  .react-datepicker {
    font-size: rem(16px);
    font-weight: normal;
  }
}
