.vendor-dashboard__wrapper {
  background: $grey-light;
}

.vendor-dashboard {
  @extend %container;
  @extend %grid;
  align-items: stretch;
  font-family: 'Walsheim', sans-serif;
  justify-content: space-between;
  padding-bottom: rem(30px);
  padding-top: rem(30px);

  @include breakpoint($phablet) {
    padding-bottom: 0;
  }

  .vendor-dashboard-card {
    @include columns(12);

    background: $white;
    border: 1px solid $grey-medium;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: rem(10px);
    width: 100%;

    @include breakpoint($phablet) {
      @include columns(6);
      margin-bottom: rem(50px);
    }

    @include breakpoint($laptop) {
      @include columns(4);
    }

    .vendor-dashboard-card--inner-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: stretch;
    }
  }

  .vendor-dashboard-card__header,
  .vendor-dashboard-card__header-container,
  .vendor-dashboard-card__numbers,
  .vendor-dashboard-card__events,
  .vendor-dashboard-card__view {
    border-bottom: 1px solid $grey-medium;
  }

  .vendor-dashboard-card__header-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .vendor-dashboard-card__header {
      border-bottom: none;
    }
  }

  .vendor-dashboard-card__numbers {
    display: none;

    @include breakpoint($phablet) {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;
      padding: rem(10px);

      p {
        color: $grey-light-dark;
        margin: 0;
      }
    }
  }

  .vendor-dashboard-card__text {
    display: none;

    @include breakpoint($phablet) {
      display: block;
      padding: 20px;

      p {
        color: $blue-dark;
        font-size: rem(14px);
        line-height: rem(25px);
      }
    }
  }

  .vendor-dashboard-card__header {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: rem(15px) rem(10px);

    h1 {
      font-size: rem(20px);
    }

    p {
      margin: 0;
    }

    @include breakpoint($phablet) {
      justify-content: center;
    }

    .vendor-dashboard-card__icon {
      width: rem(43px);
      margin-right: 12px;
      @include breakpoint($mobile) {
        margin-right: 24px;
      }
    }
  }

  .vendor-dashboard-card__events {
    display: none;

    @include breakpoint($phablet) {
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: rem(10px);

      @include breakpoint($tablet) {
        padding: rem(10px) rem(20px);
      }

      .vendor-dashboard-card__view-links {
        color: $blue-medium;
        font-size: rem(14px);
        font-weight: normal;
      }
    }
  }

  .vendor-dashboard-card__view {
    display: none;

    @include breakpoint($phablet) {
      align-items: center;
      background: $blue-medium;
      border: 0;
      color: $white;
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding: rem(10px);
    }
  }

  .vendor-dashboard-card__messages {
    display: flex;
    justify-content: space-between;
    padding: rem(11px) 0;
    width: 50%;

    &--name,
    &--unread {
      font-size: rem(14px);
      margin: 0;
    }

    &--name {
      color: $black;
    }
  }

  .vendor-dashboard-card__event-date {
    align-self: center;
    display: flex;
    flex-direction: column;

    p {
      color: $black;
      font-size: rem(14px);
      line-height: rem(25px);
      margin: 0;
    }
  }

  .vendor-dashboard-card__view-events {
    align-items: center;
    display: flex;

    p {
      margin: 0;
    }
  }
}
