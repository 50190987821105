@function get-width($cols) {
   @return ((($col * $cols) + ($gutter * ($cols - 1))) / $total-width);
}
@mixin columns($cols, $margin: true) {
  $width: get-width($cols);
  $margin-width: ($gutter / $total-width);
  $nth: 12 / $cols;
  width: $width * 100%;
  @if $margin {
    margin-right: $margin-width * 100%;

    &:last-of-type,
    &:nth-child(#{$nth}n + #{$nth}) {
      margin-right: 0;
    }
  }
}
@mixin push($cols) {
  $width: get-width($cols);
  $margin-width: ($gutter / $total-width);
  margin-left: ($width + $margin-width) * 100%;
}

// To be used as such:
//

.col-1-2 {
  @include columns(6);
}

.col-1-4 {
  @include columns(3);
}

.col-3-4 {
  @include columns(9);
}
