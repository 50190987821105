.quoting-guide {
  max-width: 740px;
  padding: 0 20px;
  width: 100vw;
}

.quoting-guide__inner {
  background-color: #fff;
  padding: 30px;
}

.quoting-guide__title {
  font-size: rem(24px);
  line-height: rem(34px);
  margin-bottom: 40px;
}

.quoting-guide__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .tip {
    margin-bottom: 25px;
  }

  .tip__text p {
    color: $blue-dark;
    font-size: rem(16px);
    line-height: rem(24px);
  }

  .tip__icon {
    padding-top: 4px;
  }

  @include breakpoint($tablet) {
    flex-wrap: nowrap;
  }
}

.quoting-guide__sub-title {
  color: $blue-dark;
  display: block;
  font-weight: 700;
  margin-bottom: 20px;
}

.quoting-guide__left,
.quoting-guide__right {
  width: 100%;

  @include breakpoint($tablet) {
    width: 48%;
  }
}

.quoting-guide__footer {
  align-items: center;
  background-color: $blue-light;
  display: flex;
  justify-content: flex-end;
  padding: 15px 30px;

  p {
    color: $blue-dark;
    margin-bottom: 0;
    margin-right: 30px;
  }

  .button {
    width: unset;
  }
}
