.icon--logo {
  color: $blue-dark;
  height: rem(30px);
  width: rem(95px);
}

.icon--marque {
  color: $blue-dark;
  height: rem(83px);
  width: rem(88px);
}

.icon--blue {
  fill: $blue-medium;
  height: rem(60px);
}

.icon--close {
  color: $grey-dark;
  width: rem(15px);
  height: rem(15px);
}

.icon--social {
  height: rem(18px);
  width: rem(18px);
}

.icon--share {
  height: rem(20px);
  width: rem(20px);
}

.icon--cutlery {
  height: rem(18px);
  width: rem(14px);
  display: inline-block;
  vertical-align: middle;
}

.icon--thumb {
  display: inline-block;
  height: rem(14px);
  width: rem(14px);
}

.icon--thumb-up {
  color: $green;
}

.icon--food {
  height: rem(22px);
  width: rem(18px);
}

.icon--calendar {
  height: rem(24px);
  width: rem(24px);
}

.icon--card {
  height: rem(16px);
  width: rem(24px);
}

.icon--map {
  height: rem(22px);
  width: rem(21px);
}

.icon--comment {
  height: rem(24px);
  width: rem(22px);
}

.icon--thumb-down {
  color: $red;
  transform: rotate(180deg) translateY(rem(-2px));
  transform-origin: rem(8px);
}

.icon--hotdog-confirmation {
  max-width: rem(150px);
  @include breakpoint($phablet) {
    max-width: rem(250px);
  }
}

.icon--share {
  fill: $grey-medium;
}

.icon--favourite {
  width: 28px;
  height: 28px;
  transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1),
    opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:active {
    transform: scale(1.4);
    opacity: 0.1;
  }
}

.icon--payments {
  width: rem(76px);
  height: rem(50px);
}

.icon--logo-footer {
  color: $blue-dark;
  height: rem(50px);
  width: rem(158px);
}
