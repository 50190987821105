$sizes: 0, xs, s, m, l, xl, xxl, xxxl;
$properties: (
  m: margin,
  mt: margin-top,
  mb: margin-bottom,
  mr: margin-right,
  ml: margin-left,
);
$base-unit: 12;
@each $shorthand, $property in $properties {
  @each $size in $sizes {
    $i: index($sizes, $size);

    .u-#{$shorthand}--#{$size} {
      #{$property}: rem(($base-unit * $i - $base-unit)) !important;
    }
  }
}

.u-mb--s-mob {
  @include breakpoint(max-width $laptop - 1) {
    margin-bottom: rem(20px);
  }
}

.u-mc {
  margin: 0 auto;
}

.u-mc--mob {
  margin: 0 auto;
  @include breakpoint($laptop) {
    margin: 0;
  }
}

.u-mt--q_widget {
  margin-top: 20px;
  @include breakpoint($min-widget-width) {
    margin-top: 0;
  }
}
