.fieldset {
  margin-top: rem(25px);
  background: #F5F5F8;
  border-radius: 2px;
  border: 1px solid $blue-light;
  padding: rem(50px) rem(20px) rem(20px);
  position: relative;
}

.fieldset__header {
  text-align: center;
  max-width: rem(426px);
  margin: 0 auto;
}

.fieldset__title {
  color: $blue-dark;
  font-size: em(18px);
  margin-bottom: rem(15px);
}

.fieldset__icon {
  position: absolute;
  width: rem(50px);
  height: rem(50px);
  left: calc(50% - #{rem(25px)});
  top: rem(-25px);
  background: $green;
  color: $white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 28px;
    height: 28px;
  }
}
