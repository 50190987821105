.social-links {
  display: flex;
}

.social-links--centered {
  justify-content: center;
}

.social-links__item {
  @include link-colors($grey-dark, $blue-medium, $blue-dark, $grey-dark);

  &:not(:last-child) {
    margin-right: rem(18px);
  }

  svg {
    display: block;
    height: rem(18px);
    width: rem(18px);
  }
}

.social-links__item--large {
  &:not(:last-child) {
    margin-right: rem(24px);
  }

  svg {
    height: rem(24px);
    width: rem(24px);
  }
}
