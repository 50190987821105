.checkbox-group {
  @include breakpoint($phablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $baseline;

    .checkbox {
      border-bottom: 1px solid $grey-medium;
      padding: $baseline/2 0;

      &:last-child,
      &:nth-last-child(2):nth-child(odd) {
        .checkbox__label {
          border-bottom: none;
        }
      }
    }
  }
}

.checkbox-button-group,
.radio-button-group {
  display: block;
  margin: rem(-5px);

  ul {
    padding: 0;
  }

  .field__error,
  .field__support-text {
    margin-left: rem(5px);
    margin-right: rem(5px);
  }
}

.checkbox-button-group {
  ul {
    margin-top: 16px;
  }
}

.checkbox-button-group__button,
.radio-button-group__button {
  display: inline-block;
  vertical-align: middle;
  margin: rem(5px);
  padding: 0;
  border: none;

  input {
    display: inline;
    opacity: 0;
    position: absolute;
  }

  input:focus + label {
    border-color: $blue-medium !important;
  }

  input:checked + label {
    background: $blue-medium;
    border-color: $blue-medium;
    color: white;
  }

  input + label {
    padding: rem(4px) rem(10px);
    font-size: rem(13px);
    border: 1px solid #d6d7dc;
    border-radius: 3px;
    transition: all 0.3s ease;
  }

  input + .checkbox__label svg {
    display: none;
  }
}

.radio-button-group-inline,
.radio-button-group__button-inline {
  display: flex;
  flex-direction: row;
}

.radio-button-group__button-inline {
  line-height: 18px;
  margin: 0 rem(40px) 0 0;
}

.radio-button-group__button-inline input {
  margin: 0 rem(10px) 0 0;
}
