.advanced-filters {
  color: $blue-dark;
}

.advanced-filters__actions-bar {
  display: flex;

  @include breakpoint(min-width $advanced-filters-inline-breakpoint + 1) {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
}

.advanced-filters__text-search {
  display: inline-flex;
  align-items: center;
  width: 290px;
  transition: border $transition-medium ease;
  margin-left: auto;

  @include breakpoint(max-width $advanced-filters-inline-breakpoint) {
    font-size: rem(12px);
    margin-left: 0;
    margin-right: auto;
  }

  input:focus {
    border-bottom-color: $blue-dark;
  }

  .icon--search {
    position: relative;
    left: -20px;
    align-self: center;
    width: 20px;
    height: 20px;
    color: $grey-light-dark;
    pointer-events: none;
  }
}

.advanced-filters__text-search-input {
  transition: border-bottom-color $transition-medium ease;
  padding-right: 20px;

  &:focus {
    border-bottom-color: $blue-medium;
  }
}

.advanced-filters__close-btn {
  @extend .u-touch-btn;
  position: fixed;
  z-index: 1;
  top: rem(7px);
  right: rem(7px);

  .icon--close {
    color: $blue-dark;
    width: rem(25px);
    height: rem(25px);
  }
}

.advanced-filters__reset-btn {
  vertical-align: middle;
}

.advanced-filters__toggle-btn {
  vertical-align: middle;
  min-width: rem(100px);

  @include breakpoint(min-width $advanced-filters-inline-breakpoint + 1) {
    display: none;
  }
}

.advanced-filters__modal {
  position: fixed;
  z-index: index($z-index, modal);
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  padding-bottom: 100px;
  overflow: auto;
  opacity: 1;
  visibility: visible;
  box-shadow: 0 10px 30px 0 rgba(153, 155, 168, 0.2);
  border-bottom: 1px solid #dde5ea;

  @include breakpoint(max-width $mobile) {
    padding-bottom: 160px;
  }
}

.advanced-filters__modal--closed {
  opacity: 0;
  visibility: hidden;
}

.advanced-filters__modal-inner {
  min-height: 100%;
}

.advanced-filters__tags {
  @extend %unstyled-list;
  position: relative;
  @include columns(12);

  @include breakpoint($phablet) {
    @include columns(6);
  }
}

.advanced-filters__tags-title {
  margin-bottom: rem(20px);
}

.advanced-filters__controls {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  border-top: 1px solid $blue-light;
}

.advanced-filters__tag {
  break-inside: avoid-column;

  & + & {
    margin-top: rem(5px);
  }
}

.advanced-filters__tags-container {
  @extend %unstyled-list;
}

.advanced-filters__tags-container--two-columns {
  columns: 2;
  width: 399px; // -1px for border
}

.advanced-filters__tags-container--inline {
  padding: rem(9px) rem(20px);
}

.advanced-filters__tags-container--button {
  padding: rem(9px);
}

.advanced-filters__tags--simple {
  @include breakpoint(0 ($phablet - 1)) {
    & + & {
      margin-top: $baseline * 2;
    }
  }

  .advanced-filters__tags-container {
    columns: 2;

    li {
      break-inside: avoid-column;
    }
  }
}

.advanced-filters__tags--small {
  margin-top: $baseline * 2;
  @include columns(6);

  .advanced-filters__tags-container {
    columns: 1;
  }
}
