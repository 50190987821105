$range-bullet-size: 20px;

.range {
  position: relative;

  &__range-labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: $range-bullet-size / 2 0;
    margin-right: -20px; // fix a bug with overflowing content
    overflow: hidden;
  }

  &__label {
    font-size: rem(15);
    text-align: center;
    flex: 1;

    &:first-child {
      padding-left: $range-bullet-size;
      transform: translateX(-50%);
    }

    &:last-child {
      padding-right: $range-bullet-size;
      transform: translateX(50%);
      margin-right: 20px; // fix a bug with overflowing content
    }

    &.range-floating {
      flex: 0;
    }

    &.active {
      color: $green;
    }
  }

  &.range-floating &__label-current {
    flex: 1;
  }

  &__floating-label {
    position: absolute;
    top: 12px;
  }

  &__range-ranges {
    position: relative;
  }

  &__range-wrapper {
    width: 100%;
  }

  &__range {
    position: relative;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: $grey-light;
    z-index: 1;

    .handle {
      position: relative;
      top: 50%;
      margin-top: -($range-bullet-size / 2) - 1;
      width: $range-bullet-size;
      height: $range-bullet-size;
      border-radius: 50%;
      border: 1px solid $grey-medium;
      background-color: white;
      touch-action: manipulation;
      box-shadow: 0px 2px 5px 0px rgba(153,155,168,0.20);

      &::before {
        content: "";
        position: absolute;
        width: $range-bullet-size + 10;
        height: $range-bullet-size + 10;
        left: -5px;
        top: -5px;
        background-color: transparent;
      }
    }

    .bar.bar-1 {
      background-color: $green;
      height: 100%;
    }
  }
}

