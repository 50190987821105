.quote-templates {
  background-color: $grey-light;

  .breadcrumbs {
    border-bottom: 1px solid $grey-medium;
  }

  .container__inner-wide {
    padding: 40px 0;
  }

  .support-card {
    margin-bottom: 0;
  }

  .container__sidebar {
    background-color: $white;
    margin-bottom: 40px;
    @include breakpoint($min-widget-width) {
      align-self: flex-start;
      margin-top: 48px;
      margin-bottom: 0;
    }
  }

  .container__inner-wide {
    .quote-templates__list {
      width: 100%;

      .quote-templates__cta {
        font-family: 'adelle-sans';
        font-size: 20px;
        color: $blue-dark;
        margin-bottom: 0;
      }

      @include breakpoint($min-widget-width) {
        margin-right: 60px;
        margin-bottom: 0;
      }
    }
  }
}

.quote-template-card {
  padding: 24px 20px;
  display: flex;
  justify-content: space-between;
  background-color: $white;
  border-radius: 6px;

  &--create {
    background-color: rgba($white, 0.5);
    justify-content: center;
  }
}

.quote-template {
  .breadcrumbs {
    border-bottom: 1px solid $grey-medium;
  }
}
