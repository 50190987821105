.payments {
  font-size: em(14px);
  width: 100%;
  color: $grey-dark;
  margin-bottom: rem(30px);

  thead {
    color: $grey-dark;
    border-top: 1px solid $grey-dark;
    border-bottom: 1px solid $grey-dark;

    td {
      padding: 10px 0px;
    }
  }

  tbody {
    tr {
      background: $white;
    }
    tr:nth-child(even) {
      background: $grey-light;
    }
  }
}

.payments--disabled {
  color: $grey-medium;
  pointer-events: none;
}

.payments__payment-failed {
  color: $red;
}
