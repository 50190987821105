.quote-header {
  width: 100%;
}

.quote-header__content {
  align-items: center;
  background-color: $blue-medium;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: $grey-medium;
  border-style: solid;
  border-width: 0 0 rem(1px);
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 40px 0 25px;
  width: 100%;
}

.quote-header__list-header {
  color: inherit;
  display: table-cell;
  font-size: rem(18px);
  font-weight: bold;
  width: 130px;

  &--hide-mobile {
    display: none;

    @include breakpoint($tablet-portrait) {
      display: table-cell;
    }
  }
}

.quote-header__wrapper {
  padding: 0 30px;
  width: 100%;

  @include breakpoint($tablet-portrait) {
    max-width: 1120px;
    width: 100%;
  }
}

.quote-header__back {
  border-color: $grey-medium;
  border-style: solid;
  border-width: rem(1px) 0;
  width: 100%;

  div {
    left: rem(25px);
    position: relative;
  }
}

.quote-header__back-link {
  font-weight: normal;
}

.quote-header__inner {
  padding: 0 30px;
  width: 100%;

  @include breakpoint($tablet-portrait) {
    max-width: 1120px;
    width: 100%;
  }
}

.quote-header__quoted {
  border-bottom: 1px solid $blue-medium;
  border-top: 1px solid $blue-medium;
  color: $blue-medium;
  display: flex;
  font-size: rem(16px);
  justify-content: center;
  padding: 5px 0;
  width: 100%;

  &--declined {
    border-bottom: 1px solid $red;
    border-top: 1px solid $red;
    color: $red;
  }
}

.quote-header__top {
  border-bottom: 1px solid $grey-medium;
  display: flex;
  flex-direction: column;

  @include breakpoint($tablet-portrait) {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 27px;
  }
}

.quote-header__count {
  color: #fff;
  padding-top: 10px;
  margin-bottom: 20px;

  @include breakpoint($tablet-portrait) {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 13px;
  }
}

.quote-header__title {
  color: inherit;
  line-height: rem(40px);

  @include breakpoint($tablet-portrait) {
    padding-right: 16px;
    width: 60%;
  }
}

.quote-header__list {
  margin-top: 20px;
  padding: 0;
}

.quote-header__list-item {
  list-style: none;
  margin-bottom: 15px;
}

.quote-header__list-content {
  color: inherit;
  display: table-cell;
  font-size: rem(18px);
  line-height: rem(28px);

  &--small {
    font-size: rem(13px);
    line-height: rem(19px);
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}