$bar-height: 10px;
$width100: 100%;
$max-num-of-steps: 10;

.get-a-quote-section {
  padding: rem(40px) 0 rem(60px);
  justify-content: center;
  align-items: center;

  &--top {
    padding-top: rem(16px);
    @include breakpoint($mobile) {
      padding-top: rem(40px);
    }
  }
}

.get-a-quote--title {
  font-size: rem(30px);
  line-height: rem(40px);
  letter-spacing: 0.5px;
  @include breakpoint($phablet) {
    font-size: em(55px);
    line-height: rem(60px);
  }
}

.get-a-quote-section__heading--white,
.get-a-quote-section__sub-heading--white {
  color: white;
}

.get-a-quote-section__sub-heading {
  max-width: 510px;
  margin: auto;
}

.get-a-quote-section--start__sub-heading {
  font-size: rem(22px);
}

.get-a-quote-section__title {
  margin-bottom: rem(30px);
  position: relative;
  padding-left: rem(36px);

  svg {
    display: inline-block;
    position: absolute;
    top: rem(1px);
    left: 0;
    color: $blue-medium;
  }
}

.get-a-quote-section__step {
  font-size: rem(13px);
  color: #9a9b9e;
  margin-bottom: rem(20px);
  display: block;
}

.get-a-quote-section__field-title {
  @extend .accordian-title;
  line-height: 1.3;
  margin-bottom: rem(20px);
}

.get-a-quote-section__field {
  margin-bottom: rem(40px);
  margin-top: 0;

  .field__error,
  .field__support-text {
    position: relative;
    display: block;
    margin-top: rem(8px);
  }

  input::placeholder {
    color: #999ba8;
  }

  textarea {
    height: rem(108px);
  }
}

.get-a-quote-section__field-error {
  @extend .field__error;
  position: absolute;
}

.get-a-quote-section__progressbar {
  height: $bar-height;
  width: 100%;
  border-radius: $bar-height/2;
  background: $grey-medium;

  .progress {
    height: $bar-height;
    background: $blue-medium;
    border-radius: $bar-height/2;
  }
  @for $num-of-steps from 2 through $max-num-of-steps {
    @for $step from 1 through $num-of-steps {
      .progress-#{$step}-#{$num-of-steps} {
        width: #{$step * $width100/$num-of-steps};
      }
    }
  }
}
