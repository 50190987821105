.multi-select {
  input {
    display: none;
  }

  svg {
    flex-shrink: 0;
  }

  .icon {
    flex-shrink: 0;
  }
}

.multi-select__items {
  columns: 1;

  @include breakpoint($mobile) {
    columns: 2;
  }

  @include breakpoint($tablet) {
    columns: 1;
  }

  @include breakpoint($laptop) {
    columns: 2;
  }
}

.multi-select__item {
  color: $grey-dark;
  border-radius: 5px;
  border: 1px solid $grey-medium;
  padding: 1.25rem 1.25rem;
  line-height: 1.25rem;

  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  margin-bottom: 8px;
}

.multi-select__item--checked {
  border-color: $blue-medium;
  color: $blue-medium;
}

.multi-select__item-label {
  margin-left: 20px;
}
