.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-unstyled-btn {
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  text-align: left;
  width: 100%;
  color: currentColor;
  border-radius: 0;
}

.u-touch-btn {
  @extend .u-unstyled-btn;
  background-color: transparent;
  box-shadow: none;
  font-size: 0;
  outline: none;
  display: flex;
  appearance: none;
  justify-content: center;
  width: rem(44px);
  height: rem(44px);
}

.u-relative {
  position: relative;
}

.u-margin-bottom {
  margin-bottom: rem(20px) !important;
}

.u-button-group {
  margin-left: auto;

  @include breakpoint(max-width $mobile) {
    width: 100%;
  }

  .button, button {
    @include breakpoint(max-width $mobile) {
      width: 100%;
    }
  }

  .button:not(:last-child), button:not(:last-child) {
    margin-right: rem(20px);

    @include breakpoint(max-width $mobile) {
      margin-right: 0;
      margin-bottom: rem(20px);
    }
  }
}

.u-columns {
  @extend %unstyled-list;
  @include breakpoint($mobile($phablet - 1)) {
    columns: 2;
  }
  @include breakpoint($phablet) {
    columns: 3;
  }

  > li {
    break-inside: avoid-column;
  }
}

.u-slide-down {
  max-height: rem(800px);
  transition: max-height $transition-long ease;
  overflow: hidden;
}

.u-slide-down--closed {
  max-height: 0;
}

.u-error {
  color: $red;
}

.u-no-scroll {
  overflow-y: hidden;
}

.u-action {
  cursor: pointer;
}

.u-highlight {
  &--blue {
    color: $blue-medium;
  }
}

.u-hide-mobile {
  display: block;

  @include breakpoint(max-width $min-widget-width - 1) {
    display: none;
  }
}

.u-hide-desktop {
  display: none;

  @include breakpoint(max-width $min-widget-width - 1) {
    display: block;
  }
}
