.vat__form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 rem(20px);

  .icon--logo-footer {
    display: none;

    @include breakpoint($phablet) {
      display: block;
    }
  }
}

.vat__inner-wrapper {
  width: 100%;
  max-width: 340px;
}

.vat__download {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
  }

  p {
    color: currentColor;
  }
}

.vat__download-inner {
  width: 100%;
  max-width: 640px;
  text-align: center;
}

.vat__form-title {
  font-size: rem(16px);
  font-family: 'adelle-sans';
}
