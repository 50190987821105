.tip {
  margin-bottom: 10px;
}

.tip__icon,
.tip__text {
  display: table-cell;
}

.tip__icon {
  padding-top: 1px;
  vertical-align: top;
}

.tip__text {
  p {
    font-size: rem(13px);
    line-height: rem(19px);
    margin-bottom: 0;
    margin-left: 12px;
  }
}
