.checkbox {
  padding: $baseline/4 0;
  color: $grey-dark;

  input {
    display: none;

    & + .checkbox__label {
      &:before,
      svg {
        flex-shrink: 0;
        display: block;
        height: rem(20px);
        width: rem(20px);
        margin-right: rem(20px) / 2;
      }

      &:before {
        border: 1px solid $grey-medium;
        content: "";
      }

      svg {
        color: $blue-medium;
        position: absolute;
        left: 0;
        background-size: contain;
        opacity: 0;
      }
    }

    &:checked + .checkbox__label svg {
      opacity: 1;
    }
  }
}

.radio + .checkbox__label {
  &:before {
    border-radius: 50%;
  }

  &:after {
    background: $blue-dark;
    border-radius: 50%;
  }
}

.checkbox--icon {
  padding: 0;

  input {
    & + .checkbox__label {
      border-radius: 5px;
      border: 1px solid $grey-medium;
      padding: rem(20px) rem(20px);
      line-height: rem(20px);
      min-width: 210px;

      .icon {
        position: static;
        display: block;
        width: rem(23px);
        height: rem(20px);
        margin-right: 0;
        opacity: 1;
        margin-bottom: rem(3px);
        margin-right: rem(20px);
        color: currentColor;

        &:last-child {
          opacity: 0;
          margin-left: auto;
          margin-right: 0;
          width: rem(14px);
          height: rem(14px);
        }
      }

      &:before {
        display: none;
      }
    }

    &:checked + .checkbox__label {
      border-color: $blue-medium;
      color: $blue-medium;

      svg {
        animation: none;
        fill: white;
      }

      .icon:last-child {
        opacity: 1;
      }
    }
  }
}

.checkbox__label {
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.checkbox__label-text {
  line-height: 1.25rem;
  padding-right: .5rem;
}

.checkbox--error {
  input + .checkbox__label:before {
    border: 1px solid $red;
  }
}
