.pay_monthly {
  small {
    line-height: rem(16px);
  }

  .range-slider {
    width: 100%;
  }

  h4 {
    margin-top: rem(40px);
  }

  hr {
    margin-bottom: rem(14px);
  }
}

.payment-form__source {
  margin-bottom: rem(20px);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row__between {
  justify-content: space-between;
}

$xy-handle-size: 30px;
$color-active: $blue-medium;
$color-bg: #dddddd;
$size: 6px;

.u-slider {
  width: 100%;
  position: relative;
  display: inline-block;
  background-color: $color-bg;

  .value {
    position: absolute;
    background-color: $color-active;
  }

  .handle {
    position: absolute;
    width: $size;
    height: $size;

    &:after {
      position: relative;
      display: block;
      content: '';
    }
  }
}

.u-slider-x,
.u-slider-y {
  .handle:after {
    width: $xy-handle-size;
    height: $xy-handle-size;
    background-color: $color-active;
    // border: 3px solid $color-active;
    border-radius: 50%;
  }
}

.u-slider-x {
  height: $size;

  .handle {
    height: 100%;
    &:after {
      top: -($xy-handle-size - $size)/2;
      left: -($xy-handle-size - $size)/2;
    }
  }

  .value {
    top: 0;
    height: 100%;
  }
}

.u-slider-y {
  width: $size;

  .handle {
    width: 100%;
    &:after {
      top: -($xy-handle-size - $size)/2;
      left: -($xy-handle-size - $size)/2;
    }
  }

  .value {
    left: 0;
    width: 100%;
  }
}

.u-slider-xy {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $color-active;
  border-radius: 0;

  .handle {
    position: absolute;

    &:after {
      position: relative;
      display: block;
      top: -$xy-handle-size/2;
      left: -$xy-handle-size/2;
      width: $xy-handle-size;
      height: $xy-handle-size;
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid #fff;
      border-radius: 50%;
      content: '';
    }
  }
}
