.field-inline {
  display: inline-block;
  margin-right: 12px;
  position: relative;

  input:focus {
    border-bottom-color: $blue-dark;
  }

  &--prefix {
    padding-left: 14px;
  }

  .field__error {
    color: $blue-dark;
  }
}

.field-inline__prefix {
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.field-inline__input {
  @extend .input--text;

  transition: border $transition-medium ease;
  width: unset;

  &--error {
    border-bottom-color: $red;
  }
}
