.received-quotes__title {
  font-size: rem(18px);
  font-weight: 500;

  &.brand-togather {
    color: $togather-black;
  }
}

.received-quotes__message {
  color: $blue-dark;
  font-family: 'adelle-sans';
  margin-bottom: 0;
  max-width: 750px;

  &.brand-togather {
    max-width: 100%;
  }
}

.received-quotes__notifications {
  grid-column: 1 / span 1;

  @include breakpoint($phablet) {
    grid-column: 1 / span 2;
  }

  @include breakpoint($header-breakpoint) {
    grid-column: 1 / span 1;
  }

  @include breakpoint($min-widget-width) {
    grid-column: 1 / span 2;
  }
}

.received-quotes__cta {
  grid-column: 1 / span 1;

  @include breakpoint($phablet) {
    grid-column: 1 / span 2;
  }

  @include breakpoint($header-breakpoint) {
    grid-column: 1 / span 1;
  }

  @include breakpoint($min-widget-width) {
    grid-column: 1 / span 2;
  }
  display: grid;
  justify-content: center;
}
