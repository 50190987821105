@font-face {
  font-display: swap;
  font-family: 'Walsheim';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/walsheim/GT-Walsheim-Medium.woff') format('woff'),
  url('../fonts/walsheim/GT-Walsheim-Medium.eot') format('embedded-opentype'),
  url('../fonts/walsheim/GT-Walsheim-Medium.ttf') format('ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Labil Grotesk';
  font-weight: 500;
  src: url('../fonts/labil-grotesk/regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Labil Grotesk';
  font-weight: 600;
  src: url('../fonts/labil-grotesk/medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Recoleta';
  font-weight: 400;
  src: url('../fonts/recoleta/regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Recoleta';
  font-weight: 500;
  src: url('../fonts/recoleta/medium.woff') format('woff');
}
