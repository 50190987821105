.event__faq {
  max-width: rem(840px);
  margin: 0 auto;

  padding-top: rem(20px);
  padding-bottom: rem(20px);

  @include breakpoint($tablet) {
    padding-top: rem(100px);
    padding-bottom: rem(100px);
  }
}