.message-card {
  position: relative;
  display: flex;
  background: $white;
  border: 1px solid $blue-light;
  padding: rem(30px);
  transition: background-color $transition-short;
  width: 100%;

  @include breakpoint(max-width ($tablet-portrait - 1)) {
    flex-direction: column;
    overflow: hidden;
    flex: 1 1 100%;
  }

  & + & {
    border-top: none;
  }

  &:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &:hover {
    background-color: $blue-light;
  }

  .button {
    @include breakpoint(max-width ($tablet-landscape - 1)) {
      margin-top: rem(10px);
    }
    @include breakpoint($tablet-landscape) {
      margin-left: auto;
    }
  }
}

.message-card--unread {
  .message-card__message {
    font-weight: bold;
    color: $blue-dark;

    &::before {
      content: "";
      display: inline-block;
      background-color: $red;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 10px;
    }
  }
}

.message-card__title {
  margin-bottom: rem(5px);
  line-height: 1.6875rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 110px;

  @include breakpoint($tablet-portrait) {
    padding-right: 0;
    font-size: em(22px);
  }
}

.message-card__infos {
  display: flex;
  flex-direction: column;

  @include breakpoint($tablet-portrait) {
    width: 30%;
    flex-shrink: 0;
  }
}

.message-card__meta {
  font-size: em(14px);
  color: $grey-light-dark;
  line-height: em(24px);
  margin-bottom: em(14px);

  @include breakpoint($tablet-portrait) {
    font-size: em(14px);
  }

  span {
    white-space: nowrap;
  }
}

.message-card__meta-dot {
  @include breakpoint($tablet-landscape) {
    display: none;
  }
}

.message-card__message {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  color: $grey-dark;

  @include breakpoint($tablet-portrait) {
    margin: auto;
    padding: rem($bleed);
  }
}

.message-card__date {
  margin: auto;
  position: absolute;
  top: rem(10px);
  right: rem(10px);
  color: $grey-light-dark;
  font-size: rem(14px);

  @include breakpoint($tablet-portrait) {
    position: static;
    white-space: nowrap;
  }
}
