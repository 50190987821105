.my-events-header {
  border-bottom: 1px solid $blue-light;
}

.my-events-header__heading {
  max-width: 690px;
}

.my-events-header__heading h2 {
  color: $white;
}