 .quote-widget {
    display:flex;
    flex-direction: column;

    .booking-widget__header {
        .booking-widget__price {
            font-family: Walsheim, sans-serif;
            font-size: 22px;
        }
    }

    .quote-card__ctas__cta {
        .button {
            margin-top: 0;
        }
    }

    .booking-buttons {
        display: flex;
        flex-flow: column;

        .quote-card__ctas__cta {
            margin-top: rem(16px);
            padding: 0 !important;
        }

        @include breakpoint($mobile) {
            flex-flow: row;

            .quote-card__ctas__cta {
                flex: 1;
                margin-top: 0;

                &:first-child {
                    margin-right: rem(8px);
                }

                &:last-child {
                    margin-left: rem(8px);
                }
            }
        }
        @include breakpoint($min-widget-width) {
            flex-flow: column;

            .quote-card__ctas__cta {
                margin-top: rem(8px);
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
        }
    }
 }

 .quote-widget--message {
    display: flex;
    flex-direction: row;
    margin: auto;
    @include breakpoint($mobile) {
        width: 75%;
    }
    @include breakpoint($tablet-portrait) {
        width: 50%;
    }
    @include breakpoint($min-widget-width) {
        width: auto;
    }
    .quote-widget--icon {
        height: rem(40px);
        @include columns(2);
        &.quality, &.chat {
          @include columns(3);
          position: relative;
          left: -3%;
        }
    }
    .quote-widget--copy {
        font-size: rem(12px);
        @include columns(10);
        line-height: rem(18px);
        h3 {
            font-size: rem(16px);
            margin-bottom: rem(5px);
        }
    }
}
