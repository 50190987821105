.row--border {
  border-bottom: 1px solid $blue-light;
}

.row--white {
  background: $white;
}

.row--nav {
  border-top: 1px solid $grey-medium;
  height: 48px;
  padding: 10px 0;

  a {
    font-weight: normal;
  }
}

.row--rounded {
  border-radius: 6px;
}

.row--grey {
  color: $grey-dark;
  background: $grey-light;
}

.row--padded-s {
  padding: rem(30px) 0;
}

.row--padded-m {
  padding: rem(30px) 0;

  @include breakpoint($tablet) {
    padding: rem(60px) 0;
  }
}

.row--padded-top-m {
  padding: rem(30px) 0 0 0;

  @include breakpoint($tablet) {
    padding: rem(60px) 0 0 0;
  }
}

.row--blue {
  h1 {
    color: $white;
  }
  background: $blue-medium url('../../images/illustrations/motion.svg') no-repeat
    62% center / contain;

  @include breakpoint(max-width 699px) {
    background-image: none;
  }
  @include breakpoint(700px) {
    background-position-x: 175%;
  }
  @include breakpoint(1000px) {
    background-position-x: 100%;
  }
}

.row--no-bg-image {
  background-image: none;
}

.row--navy {
  background: $blue-dark;

  h1,
  h2,
  h3,
  h4,
  p {
    color: $white;
  }
}

.row--flair {
  background: $blue-dark url('../../images/illustrations/motion.svg') no-repeat 62%
    center / contain;

  h1,
  h2,
  h3,
  h4,
  p {
    color: $white;
  }
}

.row--padded-l {
  padding: rem(50px) 0;
  @include breakpoint($tablet) {
    padding: rem(75px) 0;
  }
}
