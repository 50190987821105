.view {
  flex: 1 0 auto;
}

.view--contact {
  .card {
    margin-bottom: rem(40px);
  }
}

.view__row--vendor {
  @include breakpoint($tablet) {
    margin-top: 60px;
  }
}