.vendor-quotes__wrapper {
  background: $grey-light;
}

.vendor-quotes {
  @extend %unstyled-list;
  @extend %container;
  @extend %grid;
  justify-content: space-between;
}

.vendor-quotes__card {
  @include columns(12);
  
  background: $white;
  border: 1px solid $grey-medium;
  display: flex;
  flex-direction: column;
  font-family: 'Walsheim', Helvetica, Arial, sans-serif;
  margin-bottom: rem(10px);
  padding: rem(20px);
  width: 100%;

  p {
    font-size: rem(16px);
    line-height: rem(20px);
  }

  @include breakpoint($phablet) {
    @include columns(6);
    margin-bottom: rem(40px);
  }

  @include breakpoint($laptop) {
    @include columns(4);
  }
}

.vendor-quotes__card--inline {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.vendor-quotes__icon {
  margin-right: 20px;
  width: 40px;
}
