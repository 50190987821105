.share-modal {
  &--referral,
  .brand-togather {
    font-family: 'Labil Grotesk', sans-serif;

    h1 {
      font-family: 'Recoleta, serif';
      color: #121212;
      font-size: 28px;
      @include breakpoint($phablet) {
        font-size: 36px;
      }
    }

    .share-modal__info {
      p {
        font-family: 'Labil Grotesk', sans-serif;
        color: #525252;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .share-modal__copy {
      font-family: 'Labil Grotesk', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    .share-modal__copy-wrapper {
      .share-modal__link {
        background: none;
        border: 1px solid #bac8db;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        gap: 8px;

        width: 230px;
        height: 50px;

        font-family: 'Labil Grotesk', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      button {
        border-radius: 24px;
        width: 73px;
        height: 48px;
      }
    }

    .share-modal__icons-wrapper {
      .share-modal__icons {
        a {
          margin-right: 8px;
        }
      }
    }
  }

  &--quote,
  .brand-togather {
    font-family: 'Labil Grotesk', sans-serif;

    h1.share-modal__title {
      text-align: left;
      font-family: 'Recoleta';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 24px;

      color: #121212;
    }

    .share-modal__info {
      p {
        font-family: 'Labil Grotesk', sans-serif;
        color: #525252;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .share-modal__copy {
      font-family: 'Labil Grotesk', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    .share-modal__copy-wrapper {
      .share-modal__link {
        background: none;
        border: 1px solid #bac8db;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        gap: 8px;

        width: 230px;
        height: 50px;

        font-family: 'Labil Grotesk', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      button {
        border-radius: 24px;
        width: 73px;
        height: 48px;
      }
    }

    .share-modal__icons-wrapper {
      .share-modal__icons {
        a {
          margin-right: 8px;
        }
      }
    }
  }

  display: flex;
  flex-direction: column;

  position: relative;
  padding: 24px;

  h1 {
    text-align: center;
    margin-bottom: 36px;
  }

  p {
    color: $black;
  }

  background-color: white;
  width: 90vw;

  @include breakpoint($phablet) {
    width: 550px;
  }

  &--referral {
    width: 100vw;

    h1 {
      text-align: left;
      line-height: 1.1;
      margin-bottom: 21px;
      margin-top: 10px;
    }

    @include breakpoint($phablet) {
      width: 100%;
      align-items: flex-start;
    }

    .share-modal__inner-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      @include breakpoint($tablet-portrait) {
        flex-direction: row;
        justify-content: space-between;
      }
      @include breakpoint($tablet) {
        padding-right: 100px;
      }

      p {
        font-weight: bold;
        position: relative;
        top: 5px;
      }
    }

    .share-modal__info {
      font-family: 'adelle-sans';
      @include breakpoint($phablet) {
        width: 590px;
      }
      margin-bottom: 5px;
    }
  }
}

.share-modal__copy {
  display: flex;
  flex-direction: column;

  .button {
    width: 30%;
  }

  margin-bottom: 24px;

  .share-modal--referral & {
    width: 100%;
    @include breakpoint($tablet-portrait) {
      width: 52%;
    }
    margin-bottom: 0;
  }
}

.share-modal__copy-wrapper {
  display: flex;

  input {
    margin-bottom: 24px;
    margin-right: 24px;

    .share-modal--referral & {
      width: 100%;
      margin-right: 10px;
      margin-bottom: 20px;
      background-color: $grey-light;
      @include breakpoint($tablet-portrait) {
        margin-bottom: 0;
      }
    }
  }

  button {
    .share-modal--referral & {
      width: 76px;
    }
  }
}

.share-modal__icons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5px;

  .share-modal__icons {
    width: 50%;
    display: flex;

    a {
      margin-right: 16px;

      color: #121212;

      svg {
        color: #121212;
        position: relative;
        height: 24px;
        width: 24px;
        fill: $blue-dark;
      }

      &:hover svg {
        fill: $blue-medium;
      }
    }
  }
}

.share-modal__link {
  width: 100%;
  overflow-wrap: break-word;
  padding: 5px;
  border-bottom: 1px solid $grey-medium;
}

.share-modal__copy-button {
  color: $blue-medium;
}

.share-modal__copy-button:hover {
  cursor: pointer;
}
