.postcode {
  position: relative;

  input {
    min-width: rem(250px);
    color: currentColor;
    border-bottom-color: currentColor;
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  }
}

.postcode__icon {
  position: absolute;
  right: 0;
  top: rem(12px);
  width: rem(15px);
  height: rem(15px);
}
