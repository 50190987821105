.categories {
  background: $blue-medium;
}

.categories__inner {
  @extend %container;
  display: flex;
  padding-top: rem(20px);
  padding-bottom: rem(20px);
}

.categories__item {
  @include link-colors(rgba($white, .5), $white, rgba($white, .5), rgba($white, .5));
  font-weight: 900;
  font-size: .875em;

  &:not(:last-child) {
    margin-right: rem(30px);
  }
}

.categories__item--active {
  color: $white;
}
