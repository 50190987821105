.referral-stats {
  background-color: $white;
  display: flex;
  padding: 20px;
  &.brand-togather {
    background-color: $white;
    display: flex;
    padding: 20px;

    .referral-stats__title {
      font-family: 'Recoleta';
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      @include breakpoint($phablet) {
        font-size: rem(24px);
      }
    }

    .referral-stats__stat {
      font-family: 'Recoleta';
      font-size: 32px;
      font-weight: 400;
      line-height: 48px;
      @include breakpoint($phablet) {
        font-size: rem(48px);
      }
    }
  }

  @include breakpoint($phablet) {
    padding: 30px;
  }
}

.referral-stats__column {
  width: 50%;

  @include breakpoint($phablet) {
    padding-right: 20px;
    width: 40%;
  }
}

.referral-stats__title {
  font-size: rem(18px);
  line-height: rem(26px);
  margin-bottom: 10px;

  @include breakpoint($phablet) {
    font-size: rem(22px);
    line-height: rem(30px);
  }
}

.referral-stats__stat {
  color: $blue-medium;
  font-family: 'Walsheim', sans-serif;
  font-size: rem(40px);
  font-weight: 500;
  line-height: rem(60px);

  @include breakpoint($phablet) {
    font-size: rem(50px);
    line-height: rem(70px);
  }
}
