.support-card {
  align-items: center;
  border: 1px solid $blue-light;
  display: flex;
  font-size: rem(15px);
  margin-bottom: 20px;
  padding: 10px;

  p {
    font-size: rem(15px);
  }

  @include breakpoint($min-widget-width) {
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }
}

.support-card__top {
  align-items: center;
  display: flex;
  margin-right: 25px;

  @include breakpoint($min-widget-width) {
    margin-right: 0;
  }
}

.support-card__avatar {
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  height: 50px;
  position: relative;
  width: 50px;

  @include breakpoint($min-widget-width) {
    height: 92px;
    margin-bottom: 18px;
    width: 92px;
  }
}

.support-card__icon {
  bottom: 0;
  height: 43px;
  position: absolute;
  right: 0;
  transform: translate(40%, 35%);
  width: 43px;

  @include breakpoint($min-widget-width) {
    height: 80px;
    width: 80px;
  }
}

.support-card__title {
  font-weight: bold;
  margin-bottom: 0;
}

.support-card__sub-title {
  margin-bottom: 0;

  @include breakpoint($min-widget-width) {
    margin-bottom: rem(15px);
  }
}

.support-card__cta {
  cursor: pointer;
  white-space: normal;
}
