.booking-widget__inner {
  @extend %container;
  @include breakpoint($tablet) {
    position: relative;

    > * {
      top: 0;
      right: rem(20px);
      position: absolute;
      max-width: rem($booking-widget-width);
    }
  }
}

.booking-widget__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  background: $blue-dark;
  padding: rem(20px);

  .aside--top & {
    @include breakpoint($min-widget-width) {
      position: absolute;
      transform: translateY(-100%);
      width: 100%;
    }
  }
  // transition: all $transition-medium $slick-ease;
}

.booking-widget__header-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.4em;
}

.booking-widget__header--loading {
  @include breakpoint($tablet) {
    transform: translateY(0%);
  }
}


.booking-widget__hero-label {
  position: absolute;
  left: 0;
  bottom: rem(20px);
  background: $blue-dark;
  color: $white;
  padding: rem(10px);

  font-weight: bold;
}

.booking-widget__header--error {
  background: $red;
}

.booking-widget__loading-state {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  display: flex;
  transition: all $transition-medium 0.2s $slick-ease;

  .spinner {
    margin: auto;
  }
}

.booking-widget__loading-state--hidden {
  opacity: 0;
  visibility: hidden;
}

.booking-widget__datepicker,
.booking-widget__guests-input,
.booking-widget__postcode {
  &.is-hidden {
    display: none;
  }
}

.booking-widget__postcode {
  &.postcode {
    input {
      min-width: 0;
    }
  }
}

.booking-widget__datepicker {
  .search__input {
    border-bottom-color: currentColor;
  }
}

.booking-widget__datepicker--error {
  .search__input {
    border-color: $red;
  }
}

.booking-widget__price {
  font-size: em(28px);
}

.siderbar__disclaimer {
  text-align: center;
  font-size: em(12px);
  margin: rem(15px) 0 0;
  line-height: 1.5em;
}

.booking-widget__footer {
  border-radius: 2px;
  color: $grey-dark;
  background: $white;
  border: 1px solid $blue-light;
  padding: rem(20px);
  font-size: em(14px);
  text-align: center;
  box-shadow: 0 rem(2px) rem(40px) 0 rgba(153, 155, 168, 0.3);
}

.booking-widget__note {
  line-height: 1.5em;
  text-align: center;
  margin-top: 0.9375rem;
}

.booking-widget__enquiry-item {
  width: 100%;
  height: 40px;
  border-bottom: 1px black solid;
  color: $grey-dark;

  &--uppercase {
    text-transform: uppercase;
  }
}

.booking-widget__dropdown-wrapper {
  button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    color: $blue-dark;
  }
}

.booking-widget__enquiry-dropdown {
  color: $grey-dark;
  list-style-type: none;
  max-height: 60px;
  margin-bottom: 0.5em;
  margin-top: 0.6em;
  overflow-y: scroll;
  padding: 0 15px;

  li {
    padding-bottom: 6px;

    &:hover {
      color: $blue-medium;
      cursor: pointer;
    }

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.booking-widget {
  .dropdown__container {
    .dropdown {
      position: relative;
      left: 0;
      width: 100%;
    }
  }
}

.booking-widget__new-enquiry {
  align-items: center;
  background: $grey-light;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  label {
    color: $blue-medium;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
