.postcode-to-borough {
  .postcode--hidden {
    display: none;
  }
}

.postcode-to-borough__borough {
  align-items: center;
  display: flex;
  cursor: text;
}

.postcode-to-borough__borough--hidden {
  display: none;
}
