.breadcrumbs {
  &.brand-togather {
    background-color: $black;

    .container {
      max-width: 100%;
      padding-left: 40px;
    }

    a {
      color: $white;
      font-family: 'Labil Grotesk', sans-serif;
    }
  }

  background-color: $white;
  border-top: 1px solid $grey-medium;
  height: 48px;
  padding: 10px 0;

  a {
    font-weight: normal;
  }
}
