.search {
  color: $white;
  
  @include breakpoint($phablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
    
  @include breakpoint($tablet) {
      justify-content: center;
  }

  input {
    border-bottom-color: currentColor;
    margin-bottom: rem(15px);

    @include breakpoint($tablet) {
      width: rem(250px);
    }
  }

  @include breakpoint(max-width ($tablet - 1)) { 
    .button {
      width: 100%;
      overflow: hidden; // fix leaking outline
    }
  }
@include breakpoint($tablet) {
    > *:not(:last-child) {
      margin-right: rem(30px);
    }
  }
}

.search__input {
  @extend .input--text;
  @include breakpoint($phablet ($tablet - 1)) {
    width: calc(50% - 7.5px);
  }
}

.search--dark {
  color: $blue-dark;
}

.search--header {
  color: $white;
  margin: 0;
  text-align: left;
  margin: 0 auto;

  @include breakpoint($tablet) {
    color: $blue-medium;
    margin: 0 auto;
    text-align: center;
    display: inherit;
    max-width: 70%;

    > *:not(:last-child) {
      margin-right: 0;
    }
  }

  .search__input {
    @include breakpoint($phablet ($tablet - 1)) {
      width: inherit;
    }
  }

  .more--info {
    color: $white;
    font-weight: bold;

    &:hover {
      color: $white;
      text-decoration: underline;
    }

    @include breakpoint($tablet) {
      color: $blue-medium;
      font-weight: normal;

      &:hover {
        color: $blue-medium;
        text-decoration: none;
      }
    }
  }

}

.supplier--search {
  color: currentColor;
  width: 100%;

  button {
    font-size: 1.25rem;
    padding: 0;
    height: 4.5rem;
    border-bottom: 1px solid;
    border-right: none;
    border-bottom-color: currentColor;
  }
}

.header-postcode--search {
  width: 100% !important;
  margin-bottom: rem(16px);
  font-size: 1.25rem;
  height: 4.5rem;

  input {
    min-width: 100%;
    height: 4.5rem;
  }

  .postcode__icon {
    right: rem(12px);
    top: rem(28px);
  }
}
