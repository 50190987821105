.benefits-card {
  border: 1px solid $blue-light;
  padding: rem(30px);
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
  text-align: center;

  @include breakpoint($mobile) {
    flex-wrap: nowrap;
    text-align: left;
  }
}

.benefits-card__benefits {
  width: 100%;
  max-width: 32rem;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    font-size: rem(14px);
    color: #686b7b;
  }

  li {
    width: 50%;
    min-width: rem(230px);
    margin-bottom: rem(10px);
    display: flex;
    align-items: center;
    padding-right: 2px;
  }
}

.benefits-card__reviews {
  width: 140px;
  text-align: center;
  margin: 10px auto;
  @include breakpoint($mobile) {
    margin: 0;
  }
}
