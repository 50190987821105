.pricing {
  font-size: em(14px);
  width: 100%;
  color: $grey-dark;
  padding-bottom: rem(10px);

  tr {
    td:last-child {
      text-align: right;
    }
  }

  tbody {
    tr:last-child td {
      padding-bottom: rem(10px);
    }
  }

  tfoot {
    border-top: 1px solid $blue-light;
    color: $blue-dark;
    font-weight: 900;

    tr:first-child td {
      padding-top: rem(10px);
    }
  }
}