.subnav {
  border-bottom: $blue-light;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  li {
    border-left: 1px solid $blue-light;
    a {
      display: block;  
      padding: rem(7px) rem(10px);
      font-size: rem(12px);
      text-align: center;

      @include breakpoint($mobile) {
        padding: rem(13px) rem(20px);
      } 
      @include breakpoint($phablet) {
        padding: rem(20px) rem(30px);
        font-size: rem(16px);
      } 
    }

    &.active {
      background: $green;

      a {
        color: $white;
        font-weight: bold;
      }
    }
  }
  li:last-of-type {
    border-right: 1px solid $blue-light;
  }
}
