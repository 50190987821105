.referral-list {
  &.brand-togather {
    .referral-list__title {
      font-family: 'Recoleta';
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }

    .referral-list__sub-title {
      font-family: 'Labil Grotesk', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .referral-list__item {
      font-family: 'Labil Grotesk', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    .referral-list__booked-state,
    .referral-list__value {
      font-family: 'Labil Grotesk', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  background-color: $white;
  padding: 20px 20px 0;

  ul {
    margin: 0;
    padding: 0 0 30px;
    position: relative;
  }

  @include breakpoint($phablet) {
    padding: 30px 30px 0;
  }
}

.referral-list__item {
  border-top: 1px solid $grey-medium;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 8px 0;

  > div {
    align-items: center;
    display: flex;
    padding-right: 8px;
  }

  @include breakpoint($phablet) {
    border: 0;
    padding: 0 0 8px;
  }
}

.referral-list__name {
  width: 100%;

  @include breakpoint($phablet) {
    width: 35%;
  }
}

.referral-list__booked-state {
  align-items: center;
  display: flex;
  width: 80%;

  svg {
    margin-right: 8px;
  }

  @include breakpoint($phablet) {
    width: 45%;
  }
}

.referral-list__value {
  justify-content: flex-end;
  width: 20%;

  @include breakpoint($phablet) {
    justify-content: flex-start;
    width: 15%;
  }
}

.referral-list__header {
  cursor: pointer;
  padding: 0 40px 30px 0;
  position: relative;

  &:focus {
    outline: 0;
  }
}

.referral-list__chevron {
  position: absolute;
  right: 0;
  top: 20px;
  transform: rotate(90deg);

  &--closed {
    transform: rotate(0deg);
  }
}

.referral-list__title {
  font-size: rem(18px);
  line-height: rem(26px);
  margin-bottom: 20px;

  @include breakpoint($phablet) {
    font-size: rem(22px);
    line-height: rem(30px);
  }
}

.referral-list__sub-title {
  margin-bottom: 0;
}
