.shell {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.shell::before {
  @extend %pseudo-element;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: $transition-medium ease;
  transition-property: opacity, visibility;
  z-index: index($z-index, shade);
  background: rgba($blue-dark, 0.8);
  opacity: 0;
  visibility: hidden;
}

.shell--shade-modal::before {
  opacity: 1;
  visibility: visible;
}

.shell--shade-mobile::before {
  @include breakpoint(max-width $header-breakpoint) {
    opacity: 1;
    visibility: visible;
  }
}

.shell--header-transparent {
  .hero {
    padding-top: 0;

    @include breakpoint($tablet) {
      padding-top: rem(60px);
    }
  }
}
