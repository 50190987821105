.supplier-onboarding-success {
  background-color: $grey-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 30px 40px;
  width: 100%;
}

.supplier-onboarding-success__title {
  font-size: rem(32px);
  margin-bottom: 30px;
  text-align: center;
}

.supplier-onboarding-success__sub-title {
  font-size: rem(18px);
  margin-bottom: 20px;
}

.supplier-onboarding-success__desc {
  margin-bottom: 20px;
}

.supplier-onboarding-success__icon {
  width: 100px;
}
