.card {
  display: flex;
  background: $white;
  border-radius: 2px;
  border: 1px solid $blue-light;
  align-items: center;
  width: 100%;
  padding: rem(40px);

  @include breakpoint(max-width ($phablet - 1)) {
    flex-direction: column;
    text-align: center;
  }

  @include breakpoint($phablet) {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.card__content {
  width: 100%;
}

.card__actions {
  max-width: 160px;

  @include breakpoint($phablet) {
    margin-left: 40px;
  }
}

.card__actions-btn {
  width: 100%;
  white-space: nowrap;
}

.card__title {
  color: $blue-dark;
  border-bottom: 1px solid $grey-medium;
  padding-bottom: rem(10px);
  margin-bottom: rem(30px);
}

.card__list {
  @extend %unstyled-list;

  @include breakpoint($phablet) {
    columns: 3;
  }
}

.card__list-item {
  display: block;

  a {
    @include link-colors($grey-dark, $blue-medium, $grey-dark, $grey-dark);
  }
}
