.caterers {
  @extend %unstyled-list;
  @extend %container;
  @extend %grid;
  justify-content: center;
}

.caterers--event {
  margin: 0 auto rem(20px) auto;
}
