// Link Colours
@mixin link-colors(
  $normal,
  $hover: false,
  $active: false,
  $visited: false,
  $focus: false
) {
  color: $normal;
  @if $visited {
    &:visited {
      color: $visited;
    }
  }
  @if $focus {
    &:focus {
      color: $focus;
    }
  }
  @if $hover {
    &:hover {
      color: $hover;
    }
  }
  @if $active {
    &:active {
      color: $active;
    }
  }
}

// Convert pixels to rems
$em-base: 16px !default;
@function strip-units($value) {
  @return $value / ($value * 0 + 1);
}
@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }

  $base: $em-base;
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return $pxval / $base * 1rem;
}

// Convert pixels to ems
$em-base: 16px !default;
@function strip-units($value) {
  @return $value / ($value * 0 + 1);
}
@function em($pxval, $base: $em-base) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return $pxval / $base * 1em;
}

@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}

@mixin unread-dot($color: $blue-medium) {
  background: $color;
  border-radius: 50%;
  height: rem(15px);
  width: rem(15px);

  @include breakpoint($laptop) {
    height: rem(10px);
    width: rem(10px);
  }
}
