*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: rem(16px);
  color: $blue-dark;
}

body {
  overflow-y: scroll;
  line-height: 1.8em;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
  width: auto;
}

picture {
  display: block;
}

figure {
  margin: 0;
}

table {
  width: 100%;
}

svg {
  display: block;
}

a {
  @include link-colors($white, $blue-medium, $blue-dark);
  text-decoration: none;

  @media screen and (min-width: 768px) {
   @include link-colors($blue-dark);
  }

  p & {
    @include link-colors($blue-medium, $blue-medium, $blue-medium);
    display: inline-block;
  }

  &.blue {
    @include link-colors($blue-medium, $blue-medium, $blue-medium);
  }
}

hr {
  height: 1px;
  background: $grey-medium;
  border: 0;
  margin: 0;
}

textarea {
  resize: vertical;
  border: 0;
  min-height: rem(100px);
  padding: rem(10px);
  border: 1px solid $grey-medium;
}

table {
  border-collapse: collapse;
}

ul {
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.form-error {
  color: $red;
}


.messages-page {
  #fc_frame:not(.fc-open) {
    display: none;
  }
}

#fc_frame {
  position: fixed;
  bottom: 65px !important;
  right: 35px;
}
