.chevron-link {
  @include link-colors(
    $blue-medium,
    darken(desaturate($blue-medium, 19), 6),
    $blue-medium,
    $blue-medium
  );
  font-weight: 900;
  line-height: rem(24px);
  position: relative;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  transition: color $transition-medium ease;

  &::after {
    margin-left: rem(7px);
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: rem(6px) 0 rem(6px) rem(7px);
    content: '';
    border-color: transparent transparent transparent currentColor;
  }
}

.chevron-link--grey {
  @include link-colors($grey-dark, $blue-medium, $grey-dark, $grey-dark);
}

.chevron-link--blue-dark {
  @include link-colors($blue-dark, $blue-medium, $blue-dark, $blue-dark);
}

.chevron-link--before {
  position: relative;
  margin-left: rem(7px * 2);

  &::after {
    position: absolute;
    right: 100%;
    top: 50%;
    margin-left: 0;
    margin-top: rem(-6px);
    margin-right: rem(7px);
    border-width: rem(6px) rem(7px) rem(6px) 0;
    border-color: transparent currentColor transparent transparent;
  }
}

.chevron-link--back-to-bar {
  padding: 20px 0;
  font-weight: normal;
}
