.newsletter {
  width: 100%;
  display: flex;
  margin: 0 0 $baseline;

  @include breakpoint(max-width ($laptop - 1)) {
    width: rem(350px);
    margin: 0 auto $baseline;
  }

  .button {
    border-bottom-left-radius: 0;
  }
}

.newsletter__email {
  flex: 1;
  font-size: em(14px);
  color: $grey-dark;
  border-bottom: 1px solid $grey-dark;
  padding-right: rem(10px);
  @include breakpoint(max-width ($tablet-portrait - 1)) {
    width: 100%;
  }
}

.newsletter-modal {
  background: $white;
  max-width: rem(400px);
  min-width: rem(290px);
  padding: rem(25px);
  text-align: center;

  @include breakpoint($phablet) {
    padding: rem(50px);
  }
}
