.supplier-card {
  display: block;
  position: relative;
  background: $white;
  text-align: center;

  p {
    color: $blue-dark;
  }
}

.supplier-card__img {
  margin-bottom: 18px;
}
