.notifications-count {
  $height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: $height;
  min-width: $height;
  background-color: $green;
  line-height: rem(20px);
  padding: 0 6px;
  border-radius: $height;

  span {
    color: $white;
    font-size: rem(14px);
    font-family: 'Walsheim';
    text-align: center;
  }
}
