.slideshow {
  width: 115vh;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: -30px;
  opacity: 1;
  visibility: visible;
  transition: all 300ms 400ms;

  .modal--hidden & {
    opacity: 0;
    visibility: hidden;
    transition: all 300ms;
  }

  .slick-arrow {
    position: absolute;
    color: white;
    top: 50%;
    @extend .u-touch-btn;

    .icon {
      width: 100%;
      height: 100%;
      transition: transform $transition-short;
    }
  }

  .slick-next {
    left: 100%;

    &:hover .icon {
      transform: translateX(5px);
    }
  }

  .slick-prev {
    right: 100%;

    &:hover .icon {
      transform: translateX(-5px);
    }
  }
}

.slideshow__photos {
  .slick-slider {
    position: relative;
    width: 100%;
    padding-bottom: 67%;
    height: 0;
  }

  .slick-track {
    height: 100%;
  }

  .slick-slide {
    width: 100%;
    height: 100%;
    position: relative;

    > div {
      height: 100%;
    }
  }

  .slick-list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: calc(100% - 2px);
  }

  img {
    user-select: none;
    -webkit-user-drag: none;
    max-height: 90%;
    object-fit: cover;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    max-width: 85%;
    width: auto;
    margin: 0 auto;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(-50%);
  }
}

.slideshow-thumbnails {
  display: flex;
  margin-top: 5px;
  min-height: 13.8vh;

  .slick-slider {
    width: 100%;
  }

  .slick-track {
    transition: transform 500ms ease;
  }
}

.slideshow-thumbnails__item {
  padding: 0 3px;
  opacity: 0.5;
  transition: opacity $transition-medium;

  &.slick-center {
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }

  img {
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}
