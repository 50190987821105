.notification {
  opacity: 0;
  padding: rem(10px) 0;
  position: absolute;
  top: 60px;
  transition: all 60ms ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: index($z-index, notification);
  background: $grey-light;
  color: $blue-dark;

  span {
    display: block;
    padding-right: 30px;
  }
}

.notification--visible {
  opacity: 1;
  transition: none;
  visibility: visible;
}

.notification--blue {
  background-color: $blue-medium;
  color: $white;

  .notification__close {
    color: $white;
  }
}

.notification--green {
  background: $green;
  color: $white;

  .notification__close {
    color: $white;
  }
}

.notification--red {
  background: $red;
  color: $white;

  .notification__close {
    color: $white;
  }
}

.notification__container {
  position: relative;
}

.notification__close {
  background: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 6px;
}
