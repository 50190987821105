.quoted-enquiry__wrapper {
   display: flex;
   align-items: center;
   flex-direction: column;
   width: 100%;
}

.quoted-enquiry {
   width: 100%;
   padding: rem(30px) rem(30px) 0;

   @include breakpoint($tablet-portrait) {
     width: rem(720px);
   }

   ol, ul {
    list-style: initial;
    padding-left: 20px;
   }

   .quoted-enquiry__row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

   .quoted-enquiry__numbers {
      color: $black;

      &--blue {
        color: $blue-medium;
      }

      &--customer {
        font-weight: bold;
        color: $blue-dark;
      }
    }

   .quoted-enquiry__title {
      color: $blue-dark;
      font-size: rem(24px);
    }

    .quoted-enquiry__head {
      color: $blue-dark;
    }
}
