input {
  border-radius: 0;
  background: none;
  border: 0;
  padding: 0;

  &::placeholder {
    opacity: 1;
    color: currentColor;
    transition: opacity $transition-medium ease;
  }

  &:focus {
    outline: none;
  }

  &:focus::placeholder {
    opacity: .5;
  }

  &[type="password"] {
    letter-spacing: 2px;
  }
}

textarea {
  &::placeholder {
    opacity: 1;
  }
}

input[type="email"],
input[type="number"],
input[type="number"],
input[type="password"],
input[type="text"],
textarea {
  width: 100%;
}

input[type="text"] {
  -webkit-appearance: none;
}

// Remove ugly X on IE inputs
input[type=text]::-ms-clear {
  display: none;
}

// iOS overwrites border-radius on radio buttons
input[type="radio"] {
  border-radius: 3px;
}

.input--text {
  color: inherit;
  border-bottom: 1px solid $grey-medium;
  height: rem(40px);
  display: block;
}

// iOS native input does not support placeholders
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: inherit;
  display: block;
  height: 0;
}


input.search__input-has-input[type="date"]:before,
input[type="date"]:focus:before {
  content: '' !important;
}
