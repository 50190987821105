.gallery {
  display: flex;
  margin: 0 auto;
  max-width: calc(363px * 2 + 0.5rem);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .gallery__item:nth-child(1),
  .gallery__item:nth-child(2) {
    width: calc(50% - #{rem(4px)});
    margin-bottom: rem(8px);
  }

  .gallery__item:nth-child(3),
  .gallery__item:nth-child(4),
  .gallery__item:nth-child(5) {
    width: calc(33.33333% - #{rem(6px)});
  }
}

.gallery__item {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(52, 56, 81, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: all $transition-short;
  }

  &:hover:before {
    opacity: 1;
    visibility: visible;
  }
}

.gallery__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(52, 56, 81, 0.7);
  color: white;
  display: flex;
  align-content: center;
  text-align: center;
  align-items: center;
  justify-content: center;

  .icon {
    width: rem(13px);
    height: rem(13px);
    transform: rotateZ(-90deg);
    margin-left: 5px;
    margin-top: 1px;
  }
}
