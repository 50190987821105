.quote-breakdown {
  background-color: $grey-light;
  margin-bottom: 20px;
  padding: 20px;
}

.quote-breakdown__title {
  font-size: rem(24px);
}

.quote-breakdown__details {
  margin-bottom: 6px;
  padding: 0;
}

.quote-breakdown__detail {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 19px;

  &:last-child {
    margin-bottom: 0;
  }

.quote-breakdown__pay {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}

.quote-breakdown__price {
  align-self: flex-end;
  margin-right: 0;
}

 .quote-breakdown__note {
    position: absolute;
    top: 25px;
    width: 200px;
    right: -107px;
 }
}
