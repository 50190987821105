.ball {
  display: inline-block;
  background: $black;
  border-radius: 50%;
  margin: 0;
  height: rem(7px);
  width: rem(7px);
  margin: 1px 5px;
}

.ball--green {
  background: $green;
}

.ball--red {
  background: $red;
}

.ball--grey {
  background: $grey-dark;
}