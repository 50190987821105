.avatar {
  border-radius: 50%;
  width: rem(68px);
  height: rem(68px);
  align-items: center;
  display: flex;
  justify-content: center;
  background: $grey-medium;
  color: $grey-dark;
  overflow: hidden;
}

.avatar--big {
  width: rem(84px);
  height: rem(84px);
  margin-bottom: $baseline * 2;
}

.avatar--small {
  width: rem(30px);
  height: rem(30px);
  font-size: rem(13px);
}
