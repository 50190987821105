%container,
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: rem($bleed);
  padding-right: rem($bleed);
  max-width: rem(($total-width + ($bleed * 2)));
  width: 100%;
}

%container--large,
.container--large {
  max-width: rem((950px + ($bleed * 2)));
}

%container--medium,
.container--medium {
  max-width: rem((730px + ($bleed * 2)));
}

%container--small,
.container--small {
  max-width: rem((560px + ($bleed * 2)));
}

%container--narrow,
.container--narrow {
  max-width: rem((340px + ($bleed * 2)));
}

.container__inner-wide {
  width: 100%;
  @include breakpoint($min-widget-width) {
    @include columns(7);
    @include push(1);
  }
}

.container__inner-medium {
  width: 100%;
  @include breakpoint($min-widget-width) {
    @include columns(5);
    @include push(2);
  }
}

.container__inner-full {
  width: 100%;
  @include breakpoint($min-widget-width) {
    @include columns(10);
    @include push(1);
  }
}

.container__sidebar {
  width: 100%;
  position: relative;

  @include breakpoint($min-widget-width) {
    @include columns(3);
  }
}

.view--confirmation {
  .container {
    max-width: 660px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.view--booking {
  .container {
    @include breakpoint(max-width $min-widget-width - 1) {
      max-width: rem(600px);
    }
  }
}

.view--messages-thread {
  .back-messages-btn,
  .view-booking-btn {
    font-size: em(16px);
    font-family: 'Walsheim';
    margin-bottom: rem(30px);
  }

  .view-booking-btn {
    margin-left: auto;
  }

  .note {
    font-size: 14px;
  }
}

.container__favourite {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
}

.container__share {
  position: relative;
}
