.dropdown {
  margin-top: rem(15px);
  position: absolute;
  background: $white;
  min-width: rem(220px);
  right: rem(5px);
  border: 1px solid $grey-medium;
  box-shadow: 0 rem(2px) rem(6px) 0 rgba($blue-dark, 0.2);
  transition: all ease $transition-short;
  z-index: index($z-index, dropdown);
  top: 100%;

  &::before {
    @extend %pseudo-element;
    height: rem(16px);
    width: rem(16px);
    right: rem(16px);
    border-top: 1px solid $grey-medium;
    border-right: 1px solid $grey-medium;
    top: rem(-8px);
    background: $white;
    transform: rotate(-45deg);
  }
}

.dropdown__container {
  position: relative;
}

.dropdown__cta {
  position: relative;
  padding-right: rem(15px);
  display: flex;
  align-items: center;
  color: currentColor;
  user-select: none;
  outline: none;

  &::after {
    @extend %pseudo-element;
    top: 50%;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4.5px 0;
    border-color: currentColor transparent transparent;
  }
}

.dropdown__cta-count {
  font-weight: normal;
  color: $blue-medium;
}

.dropdown__item {
  @include link-colors($blue-dark, $blue-medium);
  font-size: rem(13px);
  padding-left: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  line-height: rem(21px);
  display: block;
  transition: color ease $transition-short;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-light;
  }
}

.dropdown__item--active {
  @include link-colors($blue-medium, $blue-medium, $blue-medium, $blue-medium);
}

.dropdown__item--highlighted {
  font-weight: 900;
}

.dropdown--hidden {
  pointer-events: none;
  opacity: 0;
  transform: translateY(rem(10px));
}

// Modifiers
.dropdown--inline {
  min-width: 100%;
  margin-top: 0;
  right: auto;
  left: -1px;

  &::before {
    display: none;
  }
}

.dropdown--right {
  left: auto;
  right: 1px;
}

.dropdown__cta--active {
  &::after {
    transform: translateY(-2px) rotateZ(180deg);
  }
}

.dropdown__cta--inline {
  border-right: 1px solid $grey-medium;
  font-size: rem(15px);
  min-width: 200px;
  padding: rem(20px);

  &::after {
    right: 20px;
  }

  &.dropdown__cta--active {
    box-shadow: inset 0 -2px $blue-medium;
  }
}
