// Colors
$blue-light: #dde5ea;
$blue-medium: #345ff2;
$blue-dark: #343851;
$grey-dark: #696c7b;
$grey-light-dark: #999ba8;
$grey-medium: #d6d7dc;
$grey-light: #eef3f6;
$grey-lighter: #f5f5f8;
$green: #14d37d;
$red: #ff5a5f;
$white: #fff;
$black: #000;
$yellow: #fcb610;
$shadow: rgba(153, 155, 168, 0.3);
$placeholder-text: #9197a3;
$togather-black: #121212;

// Grid settings
$total-width: 1280;
$min-width: 320;
$col: 70;
$gutter: 40;
$bleed: 20;

// Measurements
$baseline: 1.375rem;
$booking-widget-width: 290px;

// Breakpoints
$mobile: 480px;
$phablet: 640px;
$tablet-portrait: 769px;
$tablet-landscape: 1024px;
$tablet: 880px;
$laptop: 1200px;
$desktop: 1500px;
$widescreen: 1800px;
$min-widget-width: 1080px;
$advanced-filters-inline-breakpoint: 1110px;
$header-breakpoint: 970px;

// Transition Speeds
$transition-short: 0.125s;
$transition-medium: 0.25s;
$transition-long: 0.5s;

// z-index stack
$z-index: step, article, dropdown, aside, notification, shade, modal;
