.onboarding-form {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 40px;
  background: $grey-light;
  width: 100%;

  .dropdown {
    width: 100%;
    padding: 12px;
  }

  .field {
    height: 55px;
    margin-top: 0;
  }

  .form-error {
    margin-top: 10px;
  }

  .field__error {
     position: absolute;
     top: 45px;
  }

  .Select-control {
    background: transparent;
  }

  .Select-input {
    padding: 0;
    margin: 0;
  }

  .dropdown--inline {
    width: 100%;
  }

  .dropdown__cta--inline:after {
    right: 0;
  }

  .dropdown__cta {
    padding: rem(10px) 0;
    border-right: 0;
    border-bottom: 1px solid $grey-medium;
    font-size: rem(16px);
    color: $grey-dark;

    &:after {
      border-color: $blue-dark transparent transparent;
      border-width: rem(5px) rem(5px) rem(2.5px);
      margin-right: rem(5px);
    }
  }

  .onboarding-form__source {
    .field__error {
      top: 70px;
    }
  }

  .onboarding-form__supplier-type {
    .field__error {
      top: 55px;
    }
  }
}

.onboarding-form__title {
  line-height: rem(40px);
  text-align: center;

  @include breakpoint($tablet) {
    text-align: left;
  }
}

.onboarding-form__horizontal {
  display: flex;
  justify-content: space-between;
}

.onboarding-form__phone,
.onboarding-form__city {
  width: 48%;
}

.onboarding-form__label {
  position: relative;
  font-size: 12px;
  margin-bottom: 0;
  top: 5px;
}

.onboarding-form__button {
  max-width: 115px;
  font-size: 16px;
  align-self: center;
  margin-top: 18px;
}
