.field {
  margin-top: rem(10px);
  margin-bottom: rem(25px);
  position: relative;

  input:focus {
    border-bottom-color: $blue-dark;
  }
}

.field__label {
  color: $grey-dark;
  display: block;
  font-size: em(13px);
}

.field__label--textarea {
  color: $grey-dark;
}

.field__input {
  @extend .input--text;
  width: 100%;
  transition: border $transition-medium ease;
}

.field__input__card {
  margin-bottom: 25px;
}

textarea.field__input {
  &::placeholder {
    color: $grey-dark;
  }

  &:focus {
    outline: none;
    border-bottom-color: $blue-dark;
  }
}

.field__input--error {
  border-bottom-color: $red;
}

.field__label--inline {
  pointer-events: none;
  transition: all $transition-medium ease;
  font-size: rem(16px);
  line-height: rem(40px);
  position: absolute;
}

.field__label--touched {
  font-size: rem(13px);
  transform: translateY(-25px);
  color: $grey-dark;
}

.field__error,
.field__support-text {
  font-size: rem(13px);
  line-height: 1.3;
}

.field__error {
  color: $red;
}

.field__support-text {
  color: $grey-dark;
}
