.square {
  display: inline-block;
  font-size: em(14px);
  background: $blue-dark;
  height: rem(25px);
  color: $white;
  width: rem(25px);
  text-align: center;
  vertical-align: top;
}
