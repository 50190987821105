.services {
  @extend %container;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-top: rem(50px);
  padding-bottom: rem(50px);
  display: flex;
  @include breakpoint(0 999px) {
    flex-direction: column;
    align-items: center;
  }
  @include breakpoint(1000px) {
     @include columns(10, false);
    padding-top: rem(115px);
    padding-bottom: rem(115px);
    justify-content: space-between;
  }
  @include breakpoint(1400px) {
     @include columns(8, false);
  }
}

.services--event {
  @include columns(12, false);
}

.services__item {
  text-align: center;
  max-width: rem(500px);
  @include breakpoint(max-width 1000px) {
    margin-bottom: rem(40px);
  }
  @include breakpoint(1000px) {
    @include columns(6);

    .services--event & {
      @include columns(4);
    }

    &:first-child, &:nth-child(1) {
      margin-bottom: rem(80px);
    }
  }

  .services--event & {
    @include breakpoint(1000px) {
      margin: 0;
    }
  }
}


.services__img {
  height: rem(80px);
  margin-bottom: rem(35px);
}

.services__img--truck {
  height: rem(60px);
  margin-top: rem(10px);
  margin-bottom: rem(45px);
}

.services__spiel {
  margin-bottom: 0;
}
