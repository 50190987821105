.carousel-cards {
  padding: 0 0 30px;

  &--small {
    .slick-slide {
      @include breakpoint($tablet) {
        max-width: 270px!important;
      }
    }
  }

  .slick-slide {
    width: 100%;
    max-width: 270px;
    margin-right: 20px;

    @include breakpoint($tablet) {
      max-width: 400px;
    }
  }

  .supplier-card {
    width: 100%;
    margin-bottom: 0;
  }

  .slick-disabled {
    display: none !important;
  }

  .slick-arrow {
    background: transparent;
    border: 0;
    top: 38%;
    transform: translateY(-50%);
    position: absolute;
    z-index: 5;

    svg {
      position: relative;
    }
  }

  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  }

  .slick-track {
    display: flex;
  }
}
