.status-icon {
  border-radius: 50%;
  color: $white;

  &--cta {
    background: $blue-medium;
  }

  &--quote-fulfilled,
  &--quote-pending {
    background: $blue-medium;

    svg {
      transform: scale(0.9);
      margin-top: -3px;
    }
  }

  &--upcoming,
  &--pending,
  &--clock {
    background: $yellow;
  }

  &--approvedVendor,
  &--booked {
    background: $green;

    svg {
      transform: scale(0.8);
    }
  }

  &--declined,
  &--declinedVendor,
  &--declinedAdmin {
    background: $grey-dark;
  }

  &--completed,
  &--declined-past {
    background: $grey-medium;
  }
  @include breakpoint(max-width $tablet-landscape - 1) {
    padding: rem(7px);
    width: rem(36px);
    height: rem(36px);
  }
  @include breakpoint($tablet-landscape) {
    padding: rem(9px);
    width: rem(48px);
    height: rem(48px);
  }

  &--small {
    padding: 0.2rem;
    width: 1.4rem;
    height: 1.4rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
