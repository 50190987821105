.Select-control {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $grey-medium;
  transition: border $transition-medium ease;
}

.Select--single > .Select-control .Select-value,
.Select-placeholder {
  padding-left: 0;
}

.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: $blue-dark;
}

.Select-arrow {
  border-color: $blue-dark transparent transparent;
}

.Select-menu-outer {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: $white;
  border: none;
  margin-top: 1px;
  box-shadow: 0 2px 10px 0 rgba(153,155,168,0.30);
}

.Select-option {
  color: $blue-dark;
  padding: 8px 10px;
}

.Select-option.is-focused {
  background-color: $grey-light;
}

.Select.is-open .Select-control,
.is-focused:not(.is-open) > .Select-control {
  border-bottom: 1px solid $blue-dark;
  box-shadow: none;
}

.Select-input,
.Select-placeholder {
  line-height: rem(40px);
  height: rem(40px);
}

.Select-placeholder {
  color: $grey-dark;
}

.Select-arrow-zone {
  width: 10px;
  padding-right: 0;
}

.Select-menu {
  max-height: 176px;
}
