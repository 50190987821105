.info {
  background: $grey-dark;
  color: $white;
  display: inline-block;
  width: rem(16px);
  height: rem(16px);
  vertical-align: middle;
  border-radius: 50%;
  text-align: center;
  line-height: rem(16px);
  margin-right: rem(5px);
  font-weight: 900;
  font-size: em(12px);
}
