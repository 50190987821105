.admin {
  @extend %container;
  @extend %container--narrow;
  margin-top: rem(85px);
  margin-bottom: rem(80px);

  h1 {
    font-size: em(22px);
    margin-bottom: rem(40px);
    text-align: center;
  }
}

.admin__logo + p {
  text-align: center;
  color: $blue-dark;
  // font-size: em(14px);
  margin-bottom: rem(40px);
}

.admin__logo svg {
  display: block;
  text-align: center;
  margin: 0 auto rem(35px);
}

.admin__avatar {
  margin: 0 auto rem(45px);
  display: block;
}

.admin__actions {
  display: flex;
  justify-content: space-between;
}
