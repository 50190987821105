.profile {
  padding: 80px 30px 50px;
  margin: auto;
  width: 100%;
  max-width: 860px;

  @include breakpoint(max-width ($phablet - 1)) {
    max-width: 400px;
  }
}

.profile__forms {
  padding: 50px 0 0;
  display: flex;
  flex-direction: row;

  h4 {
    margin: 0 0 30px;
  }

  @include breakpoint(max-width ($phablet - 1)) {
    display: inherit;
  }
}

.profile__settings {
  padding: 25px 25px 0 0;
  width: 45%;
  margin-right: 5%;

  @include breakpoint(max-width ($phablet - 1)) {
    padding: 25px 0 0;
    width: 100%;
    margin: 0;
  }
}

.profile__payment {
  width: 50%;

  @include breakpoint(max-width ($phablet - 1)) {
    width: 100%;
    margin: 50px 0 0;
  }
}

.profile__source {
  padding: 25px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 2px;
  box-shadow: 0 0 50px #eee;

  @include breakpoint(max-width ($phablet - 1)) {
    margin: 0 -25px;
  }
}

.profile__payment--contact {
  margin: 25px 0;
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 15px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}

.profile__payment--lowlight {
  color: #ccc;
}

.profile__source--new-card__buttons,
.profile__source--current-card {
  display: flex;
  justify-content: space-between;
}

.profile__source--edit {
  cursor: pointer;
}

.profile__source--card-details {
  display: flex;
  flex-direction: row;

  svg {
    margin-top: 7px;
  }
}

.profile__source--error {
  margin: 15px 0 0;
  color: red;
  border: 1px solid #ff5a5f;
  padding: 15px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 20px;
}

.profile__source--card-expiry {
  color: #ccc;
  margin-left: 10px;
}
