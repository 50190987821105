.payment-card {
  display: flex;
  background: $white;
  border-radius: 2px;
  border: 1px solid $blue-light;
  align-items: center;
  width: 100%;
  padding: rem(20px);
  margin-bottom: rem(20px);
  @include breakpoint(max-width ($tablet-landscape - 1)) {
    flex-direction: column;
    text-align: center;
  }
  @include breakpoint($tablet-landscape) {
    padding: rem(30px);
    margin-bottom: rem(40px);
  }
  @include breakpoint($phablet) {
    .grid & {
      @include columns(6);
    }
  }

  .button {
    @include breakpoint(max-width ($tablet-landscape - 1)) {
      margin-top: rem(10px);
    }
    @include breakpoint($tablet-landscape) {
      margin-left: auto;
    }
  }
}

.payment-card__content {
  flex-grow: 3;

  @include breakpoint($tablet-landscape) {
    margin-right: rem(20px);
  }
}

.payment-card__title {
  font-size: em(22px);
  margin-bottom: rem(5px);
  line-height: 1.6875rem;
}

.payment-card__subtitle {
  color: $grey-dark;
  font-size: em(16px);
  margin-bottom: rem(5px);
}

.payment-card__meta {
  font-size: em(14px);
  margin-bottom: 0;
}

.payment-card__actions {
  width: 35%;
  max-width: 160px;

  @include breakpoint(max-width ($tablet-landscape - 1)) {
    width: auto;
  }
}

.payment-card__actions-btn {
  width: 100%;
  white-space: nowrap;

  & + & {
    margin-top: rem(5px);
  }
}

.payment-card {
  .status-icon {
    flex-shrink: 0;

    @include breakpoint(max-width ($tablet-landscape - 1)) {
      margin-bottom: rem(10px);
    }
    @include breakpoint($tablet-landscape) {
      margin-right: rem(20px);
    }
  }
}
