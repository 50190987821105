.supplier-referral {
  &.brand-togather {
    font-family: 'Labil Grotesk', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .supplier-referral__wrapper {
    section.bordered {
      border-top: solid 1px #bac8db;
      border-bottom: solid 1px #bac8db;
      border-collapse: collapse;

      &:first-of-type {
        border-top: none;
      }

      @include breakpoint($phablet) {
        border: none;
      }
    }
  }

  background-color: $grey-light;
  width: 100%;

  @include breakpoint($tablet-portrait) {
    padding: 40px;
  }
}

.supplier-referral__footnote {
  padding: 0 20px;

  @include breakpoint($tablet-portrait) {
    padding: 0;
  }
}

.supplier-referral__wrapper {
  margin: auto;

  @include breakpoint($tablet-portrait) {
    max-width: 840px;
  }
}
