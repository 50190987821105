.vendor-quote-form__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
}

.vendor-quote-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  .vendor-quote-form__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .vendor-quote-form__template-chooser--empty {
    .dropdown {
      &:before {
        background-color: $grey-light;
      }
    }
  }

  .vendor-quote-form__title-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .vendor-quote-form__title {
      color: $black;
      font-size: rem(24px);
    }

    .dropdown__container {
      display: flex;
      border: 1px solid $grey-dark;
      border-radius: 2px;
      font-size: rem(16px);
      width: 151px;
      height: 40px;
      padding: 0 16px;

      .dropdown__cta {
        font-size: inherit;
        font-weight: normal;

        &:after {
          top: calc(50% - 1px);
        }

        &--active {
          font-weight: bold;
        }

        &:hover {
          font-weight: bold;
        }
      }

      .dropdown {
        left: 0;
        right: auto;

        &::before {
          left: 1em;
          right: initial;
        }

        &__item {
          cursor: pointer;
          font-size: rem(14px);
          outline: none;

          &--create {
            align-items: center;
            background: $grey-light;
            color: $blue-medium;
            display: flex;
            justify-content: space-between;
            padding-top: 0;
            padding-bottom: 0;

            .icon {
              margin: 1em;
            }
          }
        }
      }
    }

    @include breakpoint($mobile) {
      flex-flow: row;

      .vendor-quote-form__title {
        margin-bottom: 0 !important;
      }

      .dropdown__container {
        .dropdown {
          left: auto;
          right: 0;

          &::before {
            left: initial;
            right: 1em;
          }
        }
      }
    }
  }

  .vendor-quote-form__prices {
    color: $black;

    &--blue {
      color: $blue-medium;
    }

    &--customer {
      color: $black;
      font-weight: bold;
    }
  }

  .vendor-quote-form__menu-price {
    position: relative;
  }

  .vendor-quote-form__pound-sign {
    left: 0;
    position: absolute;
    top: rem(16px);
  }

  .vendor-quote-form__menu {
    .field__label {
      margin-left: rem(15px);

      &--touched {
        margin-left: rem(0);
      }
    }

    .field__input {
      padding-left: rem(15px);
    }
  }

  .vendor-quote-form__submit {
    align-self: center;
    width: 100%;

    @include breakpoint($mobile) {
      width: rem(300px);
    }

    span {
      margin: rem(2px) 0;
    }
  }

  .field__error {
    align-self: center;
  }
}

.vendor-quote-form__vat-note {
  color: $grey-light-dark;
  display: flex;

  .vendor-quote-form__i-icon {
    background-color: $grey-light-dark;
    border-radius: 50%;
    color: $white;
    height: rem(23px);
    width: rem(23px);

    span {
      display: flex;
      justify-content: center;
      position: relative;
      top: rem(-2px);
    }
  }
}

.vendor-quote-form__sub-title {
  color: $blue-dark;
}

.vendor-quote-form__cost {
  span {
    display: inline-block;
    margin-right: 12px;
  }

  span,
  input {
    font-size: rem(20px);
  }
}

.vendor-quote-form__cost-inputs {
  margin-bottom: 10px;
}

.vendor-quote-form__cost-detail {
  color: $blue-dark;
}

.vendor-quote-form__support-text {
  color: $grey-dark;
  display: flex;
  font-size: rem(13px);
  justify-content: space-between;
  line-height: rem(19px);
}

.quote-template-form__wrapper {
  .vendor-quote-form {
    padding-bottom: 40px;
  }

  .vendor-quote-form__title-wrapper {
    display: unset;
    flex-flow: unset;
    justify-content: unset;
    padding-top: 20px;
    margin-bottom: 0;

    input[value=''] {
      color: $placeholder-text;
    }
  }

  .vendor-quote-form__sub-title {
    margin-top: 20px;
  }

  .vendor-quote-form__form-error-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.vendor-quote-form__buttons {
  display: flex;
  justify-content: center;

  &--edit {
    justify-content: space-between;
  }
}
