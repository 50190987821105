.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: index($z-index, modal);
  transition: all $transition-medium ease;
  max-width: 100%;
  max-height: 100%;

  @include breakpoint(max-width $phablet) {
    padding-top: rem(50px);
  }

  .button {
    width: 100%;
  }
}

.modal__close {
  @extend .u-touch-btn;
  @include breakpoint(max-width $phablet) {
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
  position: fixed;
  top: rem(-40px);
  right: rem(-40px);

  .icon--close {
    color: $white;
    width: rem(20px);
    height: rem(20px);
  }
}

.modal--hidden {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, calc(-50% + #{rem(10px)}));
}
