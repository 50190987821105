.aside {
  @include breakpoint($min-widget-width) {
    height: 0;
    left: 0;
    width: 100%;
  }

  input {
    width: 100%;
  }
}

.aside--top {
  @include breakpoint($min-widget-width) {
    position: absolute;
    top: 0;
  }
}

.aside--bottom {
  @include breakpoint($min-widget-width) {
    position: absolute;
    bottom: 0;
  }
}

.aside--sticky {
  @include breakpoint($min-widget-width) {
    position: fixed;
    top: 20px;
  }
}

.aside__inner {
  @extend %container;
  @include breakpoint($min-widget-width) {
    height: 0;

    > * {
      @include columns(3);
      @include push(8);
    }
  }
}
