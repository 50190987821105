.supplier-details-card {
  border: 1px solid $blue-light;
  padding: 20px 20px 4px 20px;
}

.supplier-details-card__title {
  font-size: rem(24px);
  line-height: rem(34px);
}

.supplier-details-card__label {
  color: $grey-dark;
  display: block;
  font-size: rem(12px);
  line-height: rem(17px);
  margin-bottom: 6px;
}

.supplier-details-card__detail {
  color: $blue-dark;
}

.supplier-details-card__note {
  color: $grey-dark;
  font-size: rem(13px);
  line-height: rem(19px);
}
