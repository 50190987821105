.box {
  background: $white;
  border: 1px solid $blue-light;
  padding: rem(20px);
  @include breakpoint($laptop) {
    padding: rem(40px);
  }

  .status-icon {
    margin: 0 auto rem(20px);
  }

  svg {
    margin-left: auto;
    margin-right: auto;
  }

  p {
    color: $blue-dark;
  }

  .box {
    padding: rem(20px);
    background: $grey-light;
  }
}

.box--border-blue {
  padding-top: rem(45px);
  border-top: rem(5px) solid $blue-medium;
}

.box--border-yellow {
  padding-top: rem(45px);
  border-top: rem(5px) solid $yellow;
}

.box--border-green {
  padding-top: rem(45px);
  border-top: rem(5px) solid $green;
}

.box--border-grey {
  padding-top: rem(45px);
  border-top: rem(5px) solid $grey-dark;
}

.box--border-red {
  padding-top: rem(45px);
  border-top: rem(5px) solid $red;
}
