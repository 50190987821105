.how-hero {
  @extend %container;
  text-align: center;
  background: $grey-light;
  padding: rem(20px) 0;
}

.how-hero__heading {
  text-align: center;
  max-width: rem(800px);
  margin: rem(16px) auto;
  font-size: rem(16px);
     @include breakpoint($phablet) {
      font-size: rem(20px);
    }
}

.how-hero--options-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  .how-hero--options {
    display: flex;
    flex-direction: column;
    @include columns(6);
    background: $white;
    padding: rem(10px);
    @include breakpoint($mobile){
      padding: rem(15px);
    }
    @include breakpoint($phablet){
      padding: rem(20px);
    }
    max-width: rem(460px);
    cursor: pointer;
    &:hover {
      box-shadow: 0 rem(2px) rem(8px) rem(4px) rgba(#999BA8, .3);
    }
    p {
      font-family: "Walsheim", sans-serif;
      text-align: center;
      color: $blue-medium;
      font-size: rem(16px);
      flex: 1 0 auto;
      align-self: center;
      @include breakpoint($tablet) {
        font-size: rem(24px);
      }
    }
    border-bottom: rem(7px) solid $white; 
    &.how-hero--selected {
      border-bottom: rem(7px) solid $blue-medium;
    }
    .how-hero--radio {
      align-self: center;    
      display: block;
      margin: rem(10px) 0;
    }
  }
}