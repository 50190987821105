.quote-template-card__name {
  font-family: walsheim;
  font-size: rem(20px);
  color: $blue-dark;
  margin-bottom: 0;
  font-weight: 500;
}

.quote-template-card__edit {
  font-size: rem(16px);
  color: $blue-medium;
  margin-bottom: 0;
}

.quote-template-card__inner-wrapper {
  display: flex;

  svg {
    align-self: center;
  }
}

.quote-template-card__plus {
  height: 21px;
  width: 21px;
  position: relative;
}

.quote-template-card__create {
  font-family: walsheim;
  font-size: rem(20px);
  color: $blue-dark;
  margin-bottom: 0;
}
