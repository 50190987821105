body {
  font-family: 'Labil Grotesk', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  font-family: 'Walsheim', sans-serif;
  color: $blue-dark;
}

.h1 {
  font-size: em(32px);
  line-height: rem(40px);
  letter-spacing: 0.5px;
  @include breakpoint($phablet) {
    font-size: em(42px);
    line-height: rem(50px);
  }
}

.h2 {
  font-size: em(22px);
  line-height: rem(27px);
  @include breakpoint($phablet) {
    line-height: rem(44px);
    font-size: em(36px);
  }
}

.h2--subtle {
  color: $grey-dark;
  font-size: em(16px);
  margin-bottom: rem(30px);
}

.h3 {
  color: $blue-dark;
  font-weight: 900;
  font-size: em(22px);
  margin-bottom: rem(15px);
  line-height: rem(27px);
  @include breakpoint($phablet) {
    font-size: em(32px);
    line-height: rem(40px);
    margin-bottom: rem(28px);
  }
}

.h4 {
  color: $grey-dark;
  font-size: 1em;
  margin-bottom: rem(10px);
  @include breakpoint($phablet) {
    font-size: em(26px);
    margin-bottom: rem(28px);
  }
}

.h5 {
  font-size: em(22px);
  margin-bottom: rem(25px);
  line-height: rem(25px);
}

.h6 {
  color: $grey-dark;
  font-size: em(14px);
  margin-bottom: rem(10px);
}

.p,
p {
  font-family: inherit;
  margin-top: 0;
  font-size: rem(16px);
  line-height: rem(24px);
  margin-bottom: rem(15px);
}

div[role="alert"] > p {
  margin-bottom: 0;
}

.p--blue {
  color: $blue-dark;
}

.p--smaller {
  font-size: em(12px);
}

.t--bold {
  font-weight: 900;
}

.t-blue {
  color: $blue-dark;
}

.t-error,
.t-red {
  color: $red;
}

.t-uppercase {
  text-transform: uppercase;
}

.accordian-title {
  line-height: 1em;
  font-size: 1em;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-family: 'adelle-sans', sans-serif;

  svg {
    color: $blue-medium;
  }
}

.t-blue--medium {
  color: $blue-medium;
}

.t--center {
  text-align: center;
}

.t-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

[id^=floating-ui] {
  p {
    margin-bottom: 0;
  }
}
