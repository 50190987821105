@mixin modalWidth {
  width: 100%;
  @include breakpoint($phablet) {
    width: 80%;
  }
}

@mixin modalSliderWidth {
  width: 95%;
  @include breakpoint($tablet) {
    width: 100%;
  }
}

.open-events__wrapper {
  background: $grey-light;
}

.open-events__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 rem(30px);
}

.open-events__row {
  @include columns(12);

  background: $white;
  border: 1px solid $grey-medium;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: rem(30px);

  img {
    display: none;
  }

  @include breakpoint($phablet) {
    @include columns(6);
  }

  @include breakpoint($laptop) {
    @include columns(12);
    flex-direction: row;
    padding: 0;

    img {
      display: initial;
    }
  }
}

.open-events__filters {
  background: $white;
  border: rem(1px) solid $grey-medium;
  display: flex;
  height: rem(50px);
  justify-content: space-between;
  padding: 0 20px;

  .vendor-search__datepicker .u-unstyled-btn {
    border-bottom: 1px solid $black;
  }
}

.open-events__modal-wrapper {
  align-items: center;
  background: $white;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 4;
  overflow-y: auto;

  .open-events__dropdown--x-bottom {
    @include modalSliderWidth();
  }

  .rc-slider-mark {
    @include modalSliderWidth();
  }

  .advanced-filters__tags-container {
    width: 100%;
  }

  @include breakpoint($laptop) {
    display: none;
  }
}

.open-events__filters-modal-header {
  border-bottom: 1px solid $grey-medium;
  border-top: 1px solid $grey-medium;
  display: flex;
  justify-content: center;
  width: 100%;

  .open-events__filters-modal-header-inner {
    @include modalWidth();

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: rem(20px) 0;
  }

  .open-events__filters-modal-title {
    font-size: rem(30px);
  }
}

.open-events__filters-modal-footer {
  display: flex;
  flex-direction: column;

  .open-events__filter-button {
    margin-right: 0;
  }
}

.open-events__filters-modal {
  @include modalWidth();
  background: $white;
  height: 100%;
  position: relative;

  .vendor-search__datepicker .u-unstyled-btn {
    border-bottom: 1px solid $black;
  }

  @include breakpoint($laptop) {
    display: none;
  }
}

.open-events__modal-filter-name {
  color: $black;
  font-size: rem(20px);
}

.open-events-filters__toggle-btn {
  background-color: $blue-medium;
  bottom: 0;
  height: 70px;
  position: fixed;
  width: 100%;
  z-index: 10;

  &.open-events-filters__toggle-btn--close {
    height: 40px;
    position: relative;
    width: 100px;
  }

  @include breakpoint($laptop) {
    display: none;
  }
}

.open-events__label {
  align-items: center;
  border-right: 1px solid $grey-medium;
  display: flex;
  height: rem(50px);
  min-width: rem(96px);
  padding: 0 rem(20px);
}

.open-events--inline-search {
  min-width: rem(200px);

  @include breakpoint($laptop) {
    min-width: unset;
  }
}

.open-events--inline-filters {
  .dropdown__cta {
    font-weight: bold;
  }
  @include breakpoint(max-width $laptop) {
    display: none;
  }
}

.open-events__dropdown {
  display: flex;
  width: 100%;

  button {
    min-width: unset;
    padding: 0 rem(10px);
  }
}

.open-events__date {
  padding: 0 !important;
  width: 100%;
}

.open-events__filter {
  background: $white;
  border: rem(1px) solid $grey-medium;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.open-events__dropdown--x-wide {
  margin-right: rem(25px);
  width: rem(300px);
}

.open-events__dropdown--wide {
  margin-right: 25px;
  width: 200px;
}

.open-events__dropdown--x-bottom {
  padding-bottom: 3rem;
}

.rc-slider-track {
  background: $blue-medium;
}

.rc-slider-handle {
  border: 2px solid $blue-medium;
  height: 30px;
  margin-left: -14px;
  margin-top: -12px;
  width: 30px;

  &:hover,
  &:active,
  &:focus {
    border-color: $blue-medium;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 5px $blue-medium;
  }
}

.rc-slider-dot-active {
  border-color: $blue-medium;
}

.open-events__detail.open-events__quote-count {
  text-align: center;
  width: 40%;
  font-size: rem(16px);
  margin-top: 10px;

  span {
    margin-left: 6px;
  }

  @include breakpoint($laptop) {
    line-height: rem(22px);
    flex-direction: column;
    margin-top: 0;

    span {
      display: block;
      font-size: rem(12px);
      line-height: rem(18px);
      margin-left: 0;
    }
  }
}

.open-events__detail {
  position: relative;
  display: flex;
  font-size: rem(18px);
  overflow: hidden;
  padding: 0 rem(30px);
  white-space: nowrap;
  width: 100%;

  .open-events__unread-dot {
    @include unread-dot();

    position: absolute;
    right: 20px;
    margin: 0 10px;

    &--hidden {
      visibility: hidden;
    }

    @include breakpoint($laptop) {
      flex: 0 0 auto;
      position: relative;
      right: 0;
    }
  }

  @include breakpoint($laptop) {
    align-items: center;
    font-size: rem(16px);
    justify-content: center;
    padding: rem(10px);

    &:first-of-type {
      justify-content: left;
    }
  }

  &--expand {
    padding: rem(20px) rem(28px) rem(20px) rem(30px);
    align-items: center;
    width: 100%;
    position: relative;

    @include breakpoint($laptop) {
      margin-left: rem(20px);
      padding: 0;
      width: auto;
    }
  }

  &--view {
    flex: 1;
    align-items: center;
    height: rem(40px);
    border-radius: 2px;
    background: $blue-medium;
    border: 0;
    color: $white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0;

    &:hover {
      color: $white;
    }

    @include breakpoint($laptop) {
      width: rem(76px);
      background: $white;
      border-left: 1px solid $grey-medium;
      color: $white;
      display: flex;
      margin-top: 0;
      text-overflow: ellipsis;
    }

    .open-events__button {
      margin: 0 !important;
      background: $blue-medium;
      border-radius: 2px;
      padding: 5px 20px;
      text-align: center;

      @include breakpoint($laptop) {
        width: 100%;
      }
    }
  }

  &--bold {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .open-events__detail__icon {
    margin-right: 20px;
    width: 40px;
  }
}

.open-events__sort {
  cursor: pointer;
  list-style: none;
  padding: 0 25px;

  &--selected {
    color: $blue-medium;
    font-weight: bold;
  }
}

.open-events {
  align-items: center;
  display: flex;
  flex-direction: column;

  .open-events__back {
    border-color: $grey-medium;
    border-style: solid;
    border-width: rem(1px) 0;
    width: 100%;

    div {
      left: rem(25px);
      position: relative;
    }

    .open-events__back-link {
      font-weight: normal;
    }
  }

  .open-events__details {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .open-events__details-wrapper {
      padding: rem(30px) rem(30px);
      width: 100%;

      h2 {
        font-family: 'Walsheim', sans-serif;
      }

      @include breakpoint($tablet-portrait) {
        width: rem(720px);
      }
    }

    .open-events__details-row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      hr {
        color: $grey-medium;
      }
    }

    .open-events__details-item {
      width: 50%;

      &__full {
        width: 100%;
      }
    }

    .open-events__details-title {
      font-family: 'Walsheim', sans-serif;
      font-size: rem(12px);
      margin-bottom: 0;
      margin-top: rem(10px);
    }

    .open-events__details-content {
      color: $black;
      font-size: rem(16px);
      margin-bottom: rem(10px);
    }

    .open-events__description {
      color: $black;
      font-family: 'adelle-sans', sans-serif;
      font-size: rem(16px);
      line-height: rem(24px);
      white-space: pre-wrap;
    }

    &.open-events__details--with-form {
      background-color: $grey-light;
      border-top: $grey-medium solid 1px;
      margin-top: rem(50px);
    }
  }

  .open-events__button {
    height: rem(50px);
    width: 150px;

    a {
      color: $white;
    }
  }
}

.open-events__detail--end {
  display: flex;
  align-items: center;
  padding-top: rem(16px);

  a, button {
    display: block;
    text-align: center;
    width: 100%;
  }

  @include breakpoint($laptop) {
    padding: rem(10px);
  }
}
