.hero {
  margin-top: rem(-60px);
  min-height: rem(320px);
  position: relative;
  background: $blue-light no-repeat center / cover;
  background-image: url('../../images/misc/hero.jpg');
  padding-top: 60px;

  @include breakpoint($tablet) {
    height: rem(610px);
  }

  .h1,
  .h3 {
    color: $white;
    text-align: center;
  }

  &:before {
    @extend %pseudo-element;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.hero--article {
    background-image: none;
  }

  &--lower {
    margin-top: 0;
    padding-top: 0;
  }
}

.hero-mobile {
  display: block;
  @include breakpoint($tablet) {
    display: none;
  }

  .hero__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 3;
    padding: 0 20px;
    @include breakpoint($phablet) {
      bottom: 50%;
      transform: translateY(50%);
    }
    @include breakpoint($tablet) {
      padding: 0;
    }
  }

  .button {
    margin-top: 8px !important;
  }

  .h1,
  .h3,
  .t-blue--medium {
    color: $white !important;
  }
}

.hero-desktop {
  display: none;

  @include breakpoint($tablet) {
    display: block;
  }
}

.hero--vendor {
  background-color: $white;
  background-image: none;
  display: none;

  &.hero--supplier {
    top: 60px;

    .container__favourite {
      top: -60px;
      @include breakpoint($phablet) {
        top: -65px;
      }
      @include breakpoint($tablet) {
        top: -50px;
      }
   }
}

  @include breakpoint($tablet) {
    display: block;
  }

  &__image {
    $gap-size: rem(16px);

    &-container {
      display: grid;
      grid-gap: $gap-size;
      grid-template-columns: 2fr repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);

      &,
      &--single {
        border-top: 1px solid $blue-light;
        bottom: 0;
        height: 100%;
        left: 0;
        padding: $gap-size;
        position: absolute;
        top: 0;
        width: 100%;

        .hero--vendor__image {
          display: block;

          &__imgix {
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 100%;
          }
        }
      }

      .hero--vendor__image {
        &:first-child {
          grid-column: 1;
          grid-row: 1 / span 2;
        }

        &:nth-child(2) {
          grid-column: 2;
          grid-row: 1;
        }

        &:nth-child(3) {
          grid-column: 3;
          grid-row: 1;
        }

        &:nth-child(4) {
          grid-column: 2;
          grid-row: 2;
        }

        &:nth-child(5) {
          grid-column: 3;
          grid-row: 2;
        }
      }

      &--single {
        .hero--vendor__image {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

.hero__nav {
  border-top: 1px solid $grey-medium;
}

.hero--shade-pride {
  overflow: hidden;

  &:before {
    background-image: url('../../images/misc/pride-flag.png');
    background-size: contain;
    transform: rotate(-45deg) scale(3);
    opacity: 0.7;
  }
}

.hero--shade-dark:before {
  background: rgba($blue-dark, 0.8);
}

.hero__hgroup {
  @mixin absolute-center() {
    position: absolute;
    z-index: 1; // over the search/filter bar
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @extend %container;
  position: relative;

  .hero--landing & {
    @include breakpoint(max-width($tablet - 1)) {
      margin-top: rem(50px);
      margin-bottom: rem(20px);
    }
    @include breakpoint($tablet) {
      @include absolute-center;
      text-align: center;
      max-width: rem(640px);
    }
  }

  .hero--event & {
    max-width: rem(880px);
    text-align: center;
    margin-bottom: 0;
  }

  .hero--article & {
    @include absolute-center;
    text-align: center;
    max-width: rem(640px);
  }
}

.hero--home {
  @media screen and (max-width: $tablet - 1) {
    background-image: none !important;
  }
}

.hero__top {
  position: relative;

  &:after {
    content: '';
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.35);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.hero__inner {
  @extend %container;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;

  @include breakpoint($phablet) {
    padding: rem(60px) rem(20px);
    align-items: center;
  }
}

.hero-modal {
  padding: rem(20px);
  position: relative;
  width: 100%;
  background: $white;

  @include breakpoint($tablet) {
    min-width: 640px;
    width: 50%;
  }
}

.hero__description {
  max-width: rem(500px);
  margin: 20px auto 50px;
  color: $white;
}

.hero__buttons {
  padding: 0 0 50px;

  @include breakpoint($tablet) {
    padding: 0;
  }
}

.hero__button {
  @extend .button--big;

  & + & {
    margin: 20px 0 0 0;
  }

  @include breakpoint(410px) {
    & + & {
      margin: 0 0 0 40px;
    }
  }
}

.hero__subtitle {
  color: $grey-light-dark;
  font-weight: 900;
  margin-bottom: rem(20px);
  @include breakpoint($tablet) {
    margin-bottom: rem(40px);
  }
}

.hero__content-left {
  max-width: 600px;
}

.hero--content-left {
  display: flex;
  align-items: center;

  > div {
    z-index: 1;
  }

  h5 {
    color: white;
    text-transform: uppercase;
    font-weight: normal;
  }
}

.hero--home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: unset;

  .search {
    color: $blue-medium;
  }

  .h1,
  .h3 {
    color: $blue-dark;
    text-align: center;
  }

  p {
    color: $blue-dark;

    &.recommendations__quote--overlay {
      color: $white;
    }
  }
}

.hero--vendor--mobile {
  position: relative;

  .container__favourite {
    padding: 24px 20px;
    position: absolute;
    right: 0;
    z-index: 2;
  }

  .slick-slider {
    align-items: center;
    display: flex;
    max-height: 310px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .button-slider {
      background: $white;
      border: 0;
      border-radius: 100%;
      box-shadow: 0 rem(3px) rem(13px) 0 $shadow;
      height: 38px;
      outline: none;
      padding: 0;
      position: absolute;
      width: 38px;
      z-index: 2;

      .button-slider-icon {
        background: transparent;

        &,
        &-inner {
          display: block;
          height: inherit;
          width: inherit;
        }

        &-inner {
          position: relative;

          &::before,
          &::after {
            border: solid transparent;
            content: '';
            height: 0;
            left: 50%;
            margin-top: -3px;
            pointer-events: none;
            position: absolute;
            top: 50%;
            width: 0;
          }

          &::before {
            border-top-color: $black;
            border-width: 12px;
            margin-left: -12px;
          }

          &::after {
            border-top-color: $white;
            border-width: 10px;
            margin-left: -10px;
          }
        }
      }

      &.button-next {
        right: 20px;

        .button-slider-icon {
          transform: rotate(-90deg);
        }
      }

      &.button-prev {
        left: 20px;

        .button-slider-icon {
          transform: rotate(90deg);
        }
      }
    }

    .slick-list {
      max-height: inherit;
      width: 100%;
      z-index: 1;
    }

    .slick-track {
      height: 310px;
    }

    .slick-slide {
      > div {
        display: flex;
        align-items: center;
        height: 100%;
      }

      img {
        width: 100%;
      }
    }
  }

  @include breakpoint($tablet) {
    display: none;
  }
}

.hero--vendor__image {
  figure {
    &:hover {
      &:after {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
