.trust-markers {
  @extend .container;
  padding: 40px 0px;
}

.trust-markers__item {
  padding: rem(10px);
  display: inline-block;
  @include breakpoint(max-width ($tablet - 1)) {
     @include columns(6);
  }
  @include breakpoint($tablet) {
     @include columns(3);
  }
}

.trust-markers--valign-top {
  vertical-align: top !important;
}

.trust-markers__logo {
  width: 100%;
  margin: 20px auto;
  max-height: rem(50px);
  @include breakpoint($tablet) {
    margin: 40px auto 20px;
    max-height: rem(100px);
  }
}

.trust-markers__quote {
  color: $grey-dark;
  text-align: center;
  font-weight: 900;
  font-family: "Walsheim", sans-serif;
  display: block;
  font-size: rem(16px);
  height: rem(30px);

  @include breakpoint($tablet) {
    font-size: rem(20px);
    height: rem(40px);
  }
}
